import {
  ComponentType,
  useState,
  useContext,
  createContext,
  useEffect,
} from 'react';
import {
  ISpace,
  IOrganizationDetailed,
  setCurrentSpaceInStorage,
  removeCurrentSpaceFromStorage,
  removeCurrentOrganizationFromStorage,
  setCurrentOrganizationInStorage,
  REF_VMS_MODES,
  DEPRECATED_VMS_MODES,
} from '@datapeace/1up-frontend-shared-api';
import {
  getRestrictedVMSModeFromStorage,
  getSettingsFromLocalStorage,
  ISettings,
  saveSettingsIntoLocalStorage,
  setRestrictedVMSModeInStorage,
} from '@datapeace/vms-web-utils';
import { IConfigContext } from '@datapeace/vms-web-utils';
import { useProfileContext } from '@datapeace/vms-web-models';
import {
  useGetCurrentOrganization,
  useGetCurrentSpace,
  useGetConfig,
  useGetOrganizations,
} from '@datapeace/vms-web-hooks';

const ConfigContext = createContext<IConfigContext | null>(null);

export const useConfig = () => {
  const config: IConfigContext | null = useContext(ConfigContext);
  if (!config) {
    throw new Error("ConfigContext should be consumed inside it's Provider");
  }

  // const mockedConfig: IConfigContext & { isContactLessMode?: boolean } = config;
  // mockedConfig.isContactLessMode = true;

  return config;
};

export const withConfig = (Comp: ComponentType) => (props: any) => {
  const context = useConfig();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Comp {...context} {...props} />;
};

export function useConfigContainer() {
  const { email } = useProfileContext();

  const [error, setError] = useState('');

  const [organizations] = useGetOrganizations(setError);
  const [currentOrganization, setCurrentOrganization] =
    useGetCurrentOrganization(organizations);
  const [currentSpace, setCurrentSpace] =
    useGetCurrentSpace(currentOrganization);
  const [config, setConfig] = useGetConfig(
    currentOrganization,
    currentSpace,
    setError
  );

  const { visitInvitationEnabled, visitorAppEnabled, vmsModes, isMobilePlan } =
    config || {};

  useEffect(() => {
    const settingData = getSettingsFromLocalStorage(email);
    const restrictedModeVMS = getRestrictedVMSModeFromStorage();

    if (
      settingData.vmsMode === REF_VMS_MODES.INVITED_QR_ONLY &&
      visitInvitationEnabled === false
    ) {
      settingData.vmsMode = REF_VMS_MODES.AUTO_CAPTURE_MODE_FACE;
    }

    if (
      settingData.vmsMode === REF_VMS_MODES.CONTACT_LESS_MODE &&
      visitorAppEnabled === false
    ) {
      settingData.vmsMode = REF_VMS_MODES.NORMAL_MODE;
    }

    if (
      settingData?.vmsMode &&
      !Object.values(REF_VMS_MODES)?.includes(settingData?.vmsMode)
    ) {
      switch (settingData?.vmsMode as keyof typeof DEPRECATED_VMS_MODES) {
        case DEPRECATED_VMS_MODES.NORMAL:
          settingData.vmsMode = REF_VMS_MODES.NORMAL_MODE;
          break;
        case DEPRECATED_VMS_MODES.QUICK:
          if (isMobilePlan) {
            settingData.vmsMode = REF_VMS_MODES.QUICK_MODE_MOBILE;
          } else {
            settingData.vmsMode = REF_VMS_MODES.QUICK_MODE_FACE;
          }
          break;
        case DEPRECATED_VMS_MODES.AUTO_CAPTURE:
          settingData.vmsMode = REF_VMS_MODES.AUTO_CAPTURE_MODE_FACE;
          break;
        case DEPRECATED_VMS_MODES.QR_ONLY:
          settingData.vmsMode = REF_VMS_MODES.INVITED_QR_ONLY;
          break;
        case DEPRECATED_VMS_MODES.CONTACT_LESS:
          settingData.vmsMode = REF_VMS_MODES.CONTACT_LESS_MODE;
          break;
        default:
          settingData.vmsMode = REF_VMS_MODES.NORMAL_MODE;
      }
    }
    if (
      vmsModes &&
      settingData?.vmsMode &&
      Object.values(REF_VMS_MODES)?.includes(settingData?.vmsMode) &&
      !vmsModes?.includes(settingData?.vmsMode)
    ) {
      settingData.vmsMode = REF_VMS_MODES.NORMAL_MODE;
    }
    if (
      !!restrictedModeVMS &&
      !Object.values(REF_VMS_MODES).includes(restrictedModeVMS)
    ) {
      switch (restrictedModeVMS as keyof typeof DEPRECATED_VMS_MODES) {
        case DEPRECATED_VMS_MODES.NORMAL:
          setRestrictedVMSModeInStorage('normal_mode');
          settingData.vmsMode = REF_VMS_MODES.NORMAL_MODE;

          break;
        case DEPRECATED_VMS_MODES.QUICK:
          if (isMobilePlan) {
            setRestrictedVMSModeInStorage('quick_mode_mobile');
            settingData.vmsMode = REF_VMS_MODES.QUICK_MODE_MOBILE;
          } else {
            setRestrictedVMSModeInStorage('quick_mode_face');
            settingData.vmsMode = REF_VMS_MODES.QUICK_MODE_FACE;
          }
          break;
        case DEPRECATED_VMS_MODES.AUTO_CAPTURE:
          setRestrictedVMSModeInStorage('auto_capture_mode_face');
          settingData.vmsMode = REF_VMS_MODES.AUTO_CAPTURE_MODE_FACE;

          break;
        case DEPRECATED_VMS_MODES.QR_ONLY:
          setRestrictedVMSModeInStorage('invited_only_qr');
          settingData.vmsMode = REF_VMS_MODES.INVITED_QR_ONLY;

          break;
        case DEPRECATED_VMS_MODES.CONTACT_LESS:
          setRestrictedVMSModeInStorage('contactless');
          settingData.vmsMode = REF_VMS_MODES.CONTACT_LESS_MODE;

          break;
        default:
          setRestrictedVMSModeInStorage('normal_mode');
          settingData.vmsMode = REF_VMS_MODES.NORMAL_MODE;
      }
    }
    if (
      !!restrictedModeVMS &&
      vmsModes &&
      Object.values(REF_VMS_MODES).includes(restrictedModeVMS) &&
      !vmsModes?.includes(restrictedModeVMS)
    ) {
      setRestrictedVMSModeInStorage('normal_mode');
      settingData.vmsMode = REF_VMS_MODES.NORMAL_MODE;
    }
    if (
      !!restrictedModeVMS &&
      vmsModes &&
      Object.values(REF_VMS_MODES).includes(restrictedModeVMS) &&
      vmsModes?.includes(restrictedModeVMS)
    ) {
      settingData.vmsMode = restrictedModeVMS;
    }
    saveSettingsIntoLocalStorage(email, settingData as ISettings);
  }, [
    email,
    visitInvitationEnabled,
    visitorAppEnabled,
    vmsModes,
    isMobilePlan,
  ]);

  // handle space selection of user
  const handleSelectSpace = (space: ISpace | null) => {
    if (space) {
      setCurrentSpace(space);
      setCurrentSpaceInStorage(email, space.id);
    } else {
      setCurrentSpace(null);
      removeCurrentSpaceFromStorage(email);
    }

    // reset config as it depends on selected space
    setConfig(null);
  };
  const handleClearSpace = () => handleSelectSpace(null);

  // handle organization selection of user
  const handleSelectOrganization = (
    organization: IOrganizationDetailed | null
  ) => {
    if (organization) {
      if (
        !organization.features.organizationsVmsBasic ||
        !organization.canAccessVms
      ) {
        setError("Can't access vms");
        return;
      }

      setCurrentOrganization(organization);
      setCurrentOrganizationInStorage(email, organization.id);
    } else {
      setCurrentOrganization(null);
      removeCurrentOrganizationFromStorage(email);
    }

    // reset space as it depends on selected organization
    handleSelectSpace(null);
  };
  const handleClearOrganization = () => {
    handleSelectOrganization(null);
    setError('');
  };

  return {
    email,
    error,
    config,
    organizations,
    currentOrganization,
    currentSpace,
    handleClearSpace,
    handleClearOrganization,
    Provider: ConfigContext.Provider,
    handleSelectSpace,
    handleSelectOrganization,
  };
}
