/* eslint-disable no-bitwise */
export async function removeDeprecatedDeviceFingerprintId() {
  const fingerprintStorageKey = 'device-fingerprint';

  const storedFingerprint = localStorage.getItem(fingerprintStorageKey);
  if (storedFingerprint) {
    localStorage.removeItem(fingerprintStorageKey);
  }
}

export function generateUUID(): string {
  // Generate 16 bytes of random data
  const buffer = new Uint32Array(16);
  window.crypto.getRandomValues(buffer);

  // Set the version (4) and variant (random) bits
  buffer[6] &= 0x0f;
  buffer[6] |= 0x40;
  buffer[8] &= 0x3f;
  buffer[8] |= 0x80;

  // Convert the buffer to a hexadecimal string
  const hex = Array.from(buffer, (byte) =>
    `0${byte.toString(16)}`.slice(-2)
  ).join('');

  // Insert dashes at the appropriate positions to match UUID format
  return `${hex.slice(0, 8)}-${hex.slice(8, 12)}-4${hex.slice(
    13,
    16
  )}-a${hex.slice(17, 20)}-c${hex.slice(20)}`;
}

export async function getDeviceFingerprint() {
  const fingerprintStorageKey = 'device-id';

  const storedFingerprint = localStorage.getItem(fingerprintStorageKey);
  if (storedFingerprint) {
    return storedFingerprint;
  }
  // Get a visitor identifier when you'd like to.
  const visitorId = await generateUUID();

  localStorage.setItem(fingerprintStorageKey, visitorId);

  return visitorId;
}

export const updateUrlPath = (path = '/') =>
  window.history.replaceState(null, document.title, path);
