/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import {
  getVisitorBadgeImageBase64,
  sendPrintRequest,
  IFormFieldData,
  IClientMetaData,
  convertToCamelCase,
  getDeviceFingerprint,
} from '@datapeace/1up-frontend-shared-api';
import { captureException } from '@sentry/react';
import { Alert, Button, Collapse, Descriptions } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import * as Sentry from '@sentry/react';
import { IErrorResponse } from '@datapeace/1up-frontend-web-ui';
import moment from 'moment';
import {
  getValueObject,
  normalizeValueObject,
} from '@datapeace/vms-web-components';
import {
  useRouter,
  ProcessType,
  useProcessDataContext,
  useProfileContext,
} from '@datapeace/vms-web-models';
import {
  Api,
  getSettingsFromLocalStorage,
  getVisibleFields,
  FREQUENCY_OPTIONS,
  ROUTES,
  SENTRY_DSN,
} from '@datapeace/vms-web-utils';
import { useConfig, useRedirectOnDone } from '@datapeace/vms-web-hooks';
import { getFieldsDataFromConfig } from './useCustomFormContainer';
import styles from './useConfirmContainer.module.scss';
import {
  getDeviceGeolocation,
  getPermissionState,
  useIsMounted,
} from '@datapeace/1up-frontend-web-utils';

type ICustomMessage = {
  title: string;
  displayText?: string | React.ReactNode;
  extraJSX?: React.ReactNode;
} | null;

export function useConfirmContainer() {
  const {
    registerData,
    processType,
    customFormData,
    screeningFormData,
    activeVisit,
    faceData,
    signatureImage,
    tncEmail,
    invitationId,
    verifyOtpAttempt,
    isScreeningRequired,
    bodyTemp,
    bodyTempUnit,
    visitRequestId,
    vaccinationVerificationData,
    isVaccinationVerificationRequired,
    otpTicket,
    hasSkippedMobileVerification,
    getBackNavigationRoute,
  } = useProcessDataContext();
  const config = useConfig();
  const isMountedRef = useIsMounted();
  const { setCurrentRoute } = useRouter();

  const profile = useProfileContext();

  const isCheckinProcess = processType === ProcessType.Checkin;

  const [status, setStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle');
  // const [message, setMessage] = useState('');
  const [customMessage, setCustomMessage] = useState<ICustomMessage>(null);

  const [resendDelay, shouldRedirect] = useRedirectOnDone(status);

  function getErrorJSX(sentryJSX: React.ReactNode, err: any) {
    return (
      // @ts-ignore
      <Collapse className={styles.TechnicalDetailsCollapse}>
        {/* @ts-ignore */}
        <CollapsePanel header="Technical Details" key="1">
          <Descriptions column={1}>
            <Descriptions.Item label="Timestamp">{`${moment().format(
              'lll'
            )}`}</Descriptions.Item>
            <Descriptions.Item label="Request Id">
              {err?.response?.headers?.['x-1upvision-request-id']}
            </Descriptions.Item>
            <Descriptions.Item label="Message">
              {err?.message}
            </Descriptions.Item>
          </Descriptions>
          {import.meta.env.NODE_ENV !== 'test' &&
          import.meta.env.VITE_APP_ENVIRONMENT !== 'local' &&
          SENTRY_DSN
            ? sentryJSX
            : null}
        </CollapsePanel>
      </Collapse>
    );
  }

  const handleSubmit = useCallback(async () => {
    const {
      checkinFormSchema,
      checkoutFormSchema,
      currentSpace,
      currentOrganization,
      printer,
      printFrequency,
      checkinFormSchemaRevisionId,
      checkoutFormSchemaRevisionId,
      isMobilePlan,
      screeningFormSchema,
    } = config;

    try {
      if (!currentSpace) {
        throw new Error('Space is not selected!');
      }

      if (!registerData) {
        throw new Error('Register details not found!');
      }

      if (!isMobilePlan && !faceData) {
        throw new Error('Please take your photo first!');
      }

      setStatus('loading');
      setCustomMessage({
        title: `Checking ${isCheckinProcess ? 'in' : 'out'}`,
      });

      let successCustomMessage: ICustomMessage = {
        title: `Checked ${isCheckinProcess ? 'in' : 'out'} successfully!`,
      };

      const fields = getFieldsDataFromConfig(
        isCheckinProcess,
        checkinFormSchema,
        checkoutFormSchema
      ).filter((field) => field.processType === processType);

      const activeVisitValues = normalizeValueObject(
        activeVisit && activeVisit.checkinCustomData,
        checkinFormSchema?.fields
      );
      const values = { ...activeVisitValues, ...customFormData };
      const fieldsWithIsVisibleProperty = getVisibleFields(
        fields,
        values,
        processType,
        true
      );

      const fieldsData: IFormFieldData[] = fieldsWithIsVisibleProperty.reduce(
        (result: IFormFieldData[], field) => {
          if (field.isVisible) {
            const { name } = field;
            const { [name]: fieldValue = null } = customFormData || {};

            // if field is not visible value should be empty
            result.push(getValueObject(field, fieldValue) as IFormFieldData);
          }
          return result;
        },
        []
      );
      const { vmsMode } = await getSettingsFromLocalStorage(profile.email);

      let currentMetaData: IClientMetaData | null = {
        client: {
          deviceId: '',
          geoCoord: undefined,
          vmsMode: 'normal_mode',
        },
      };
      currentMetaData.client.vmsMode = vmsMode || 'normal_mode';
      try {
        const deviceId = await getDeviceFingerprint();
        currentMetaData.client.deviceId = deviceId;
      } catch (e) {
        console.error('No device fingerprint found!');
      }

      try {
        if ((await getPermissionState('geolocation')) === 'granted') {
          const geoCoords = await getDeviceGeolocation();
          const { longitude, latitude, accuracy } = geoCoords;
          currentMetaData.client.geoCoord = {
            long: longitude,
            lat: latitude,
            accuracyM: accuracy,
          };
        }
      } catch (e) {
        console.error('No device location found!');
      }

      currentMetaData =
        currentMetaData.client.deviceId ||
        currentMetaData.client.geoCoord ||
        currentMetaData.client.vmsMode
          ? currentMetaData
          : null;

      if (isCheckinProcess) {
        const checkinRequestData = {
          checkinVerifyOtpAttempt: verifyOtpAttempt,
          invitation: invitationId || undefined,
          checkinPhotoUrl: faceData?.imageUrl,
          checkinCustomData: {
            data: fieldsData,
            formSchemaRevisionId: checkinFormSchemaRevisionId,
            identifier: checkinFormSchema.identifier,
          },
          screeningFormData: isScreeningRequired
            ? {
                identifier: screeningFormSchema.identifier,
                data: screeningFormSchema?.fields.map((field) => {
                  const { name } = field;
                  const { [name]: fieldValue = null } = screeningFormData || {};

                  // if field is not visible value should be empty
                  return getValueObject(field, fieldValue) as IFormFieldData;
                }),
              }
            : undefined,
          checkinMetadata: currentMetaData,
          signatureImageData: signatureImage || undefined,
          sendTncCopyTo: tncEmail || undefined,
          bodyTemp: bodyTemp || undefined,
          bodyTempUnit: bodyTempUnit || undefined,
          vaccinationCertData:
            isVaccinationVerificationRequired && !!vaccinationVerificationData
              ? vaccinationVerificationData?.vaccinationCertData
              : undefined,
          vaccinationVerificationStatus:
            isVaccinationVerificationRequired && !!vaccinationVerificationData
              ? vaccinationVerificationData?.vaccinationVerificationStatus ||
                'NONE'
              : undefined,
          ticketId: otpTicket || undefined,
          otpVerificationSkipped: hasSkippedMobileVerification,
        };
        let res;
        if (vmsMode === 'contactless') {
          if (!visitRequestId) {
            throw new Error(
              'Visit Request Id not found during contactless checkin!'
            );
          }
          res = await Api.checkinVisitorByVisitRequest(visitRequestId, {
            checkinMetadata: checkinRequestData.checkinMetadata,
            bodyTemp: checkinRequestData.bodyTemp,
            bodyTempUnit: checkinRequestData.bodyTempUnit,
            checkinPhotoUrl: checkinRequestData.checkinPhotoUrl,
            ticketId: checkinRequestData.ticketId,
            otpVerificationSkipped: checkinRequestData.otpVerificationSkipped,
          });
          if (!isMountedRef.current) return;
          successCustomMessage = {
            ...successCustomMessage,
            ...res.successMessage,
          };
        } else {
          if (!visitRequestId) {
            throw new Error('Visit Request Id not found during checkin!');
          }
          res = await Api.checkinVisitorByVisitRequest(visitRequestId, {
            ...checkinRequestData,
          });
          if (!isMountedRef.current) return;
          successCustomMessage = {
            ...successCustomMessage,
            ...res.successMessage,
          };
        }

        if (
          printer &&
          printFrequency === FREQUENCY_OPTIONS.always &&
          currentOrganization?.features?.spacesVisitorBadgePrinting
        ) {
          try {
            setCustomMessage({ title: 'Printing label...' });

            try {
              const settings = await getSettingsFromLocalStorage(profile.email);
              if (!settings.pmaCode) {
                throw new Error('PMA code is not provided!');
              }
              if (!currentOrganization) {
                throw new Error('currentOrganization not found');
              }
              const labelImage = await getVisitorBadgeImageBase64(res.visit.id);
              await sendPrintRequest(
                currentOrganization.id,
                labelImage,
                printer.id.toString(),
                settings.pmaCode
              );
            } catch (err) {
              console.error((err as IErrorResponse)?.message);
            }
          } catch (err) {
            console.error((err as IErrorResponse)?.message);
            captureException(err);
          }
        }
      } else {
        if (!activeVisit) {
          throw new Error('Visit not found!');
        }

        const checkoutRequestData = {
          checkoutPhotoUrl: faceData?.imageUrl,
          checkoutMetadata: currentMetaData,
          checkoutCustomData: {
            data: fieldsData,
            formSchemaRevisionId: checkoutFormSchemaRevisionId,
            identifier: checkoutFormSchema.identifier,
          },
          ticketId: otpTicket || undefined,
          otpVerificationSkipped: hasSkippedMobileVerification,
        };

        if (vmsMode === 'contactless') {
          if (!visitRequestId) {
            throw new Error(
              'Visit Request Id not found during contactless checkout!'
            );
          }
          const { successMessage } = await Api.checkoutVisitorByVisitRequest(
            visitRequestId,
            {
              checkoutMetadata: checkoutRequestData.checkoutMetadata,
              checkoutPhotoUrl: checkoutRequestData.checkoutPhotoUrl,
              ticketId: checkoutRequestData.ticketId,
              otpVerificationSkipped:
                checkoutRequestData.otpVerificationSkipped,
            }
          );
          if (!isMountedRef.current) return;
          successCustomMessage = { ...successCustomMessage, ...successMessage };
        } else {
          if (!visitRequestId) {
            throw new Error('Visit Request Id not found during checkout!');
          }
          const { successMessage } = await Api.checkoutVisitorByVisitRequest(
            visitRequestId,
            {
              ...checkoutRequestData,
            }
          );
          if (!isMountedRef.current) return;
          successCustomMessage = { ...successCustomMessage, ...successMessage };
        }
      }

      setStatus('success');
      setCustomMessage(successCustomMessage);
    } catch (err) {
      if (!isMountedRef.current) return;
      console.error(err);
      const { nonFieldErrors } = convertToCamelCase(
        (err as IErrorResponse)?.fields || {}
      ) as {
        nonFieldErrors: string[] | undefined;
      };
      setStatus('error');
      const displayText = {
        ...convertToCamelCase((err as IErrorResponse)?.fields),
      }.displayText as string | undefined;

      const errorName = (err as any)?.response?.data?.error?.name as
        | string
        | undefined;

      setCustomMessage({
        title:
          (err as any)?.fields?.title ||
          `Check${isCheckinProcess ? 'in' : 'out'} Failed`,
        displayText:
          displayText ||
          (!!nonFieldErrors &&
            (nonFieldErrors?.length === 1 ? (
              nonFieldErrors?.[0]
            ) : (
              <ul>{nonFieldErrors?.map((errMessage) => errMessage)}</ul>
            ))) ||
          'Please check and modify the information before resubmitting.',
        extraJSX:
          !nonFieldErrors &&
          (err as any)?.response?.status === 400 &&
          errorName !== 'CheckinNotAllowed' &&
          errorName !== 'CheckoutNotAllowed'
            ? getErrorJSX(
                // eslint-disable-next-line react/jsx-indent
                <Button
                  onClick={() => {
                    Sentry.withScope((scope) => {
                      scope.setExtra(
                        'x-1upvision-request-id',
                        (err as any)?.response?.headers?.[
                          'x-1upvision-request-id'
                        ]
                      );
                      const sentryError = new Error(
                        `Error-in-${
                          isCheckinProcess ? 'checkin' : 'checkout'
                        }: ${(err as IErrorResponse).message}`
                      );
                      sentryError.stack = (err as any).stack;
                      Sentry.captureException(sentryError);
                    });
                    setCustomMessage((prev) => ({
                      ...(prev || {
                        title:
                          (err as any)?.fields?.title ||
                          `Check${isCheckinProcess ? 'in' : 'out'} Failed`,
                        displayText:
                          displayText ||
                          'Please check and modify the information before resubmitting.',
                      }),
                      extraJSX: getErrorJSX(
                        <Alert
                          message="Our team has been notified of this error"
                          type="success"
                          showIcon
                        />,
                        err
                      ),
                    }));
                  }}
                  danger
                >
                  Report this error
                </Button>,
                err
              )
            : null,
      });
    }
  }, [
    config,
    registerData,
    faceData,
    isCheckinProcess,
    customFormData,
    screeningFormData,
    invitationId,
    signatureImage,
    tncEmail,
    profile.email,
    activeVisit,
    processType,
    verifyOtpAttempt,
    isScreeningRequired,
    bodyTemp,
    bodyTempUnit,
    visitRequestId,
    isMountedRef,
    vaccinationVerificationData,
    isVaccinationVerificationRequired,
    otpTicket,
    hasSkippedMobileVerification,
  ]);

  function handleGoBack() {
    setCurrentRoute(getBackNavigationRoute(ROUTES.TEMPERATURE));
  }

  useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  const {
    firstName = '',
    lastName = '',
    mobileNumber = '',
  } = registerData || {};

  return {
    name: `${firstName} ${lastName}`,
    mobileNumber,
    faceDataUrl: faceData ? faceData.dataUrl : '',
    status,
    onSubmit: handleSubmit,
    resendDelay,
    shouldRedirect,
    customMessage,
    handleGoBack,
  };
}
