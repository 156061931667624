import { useEffect } from 'react';
import {
  useProfileContext,
  useRouter,
  useProcessDataContext,
  ProcessType,
} from '@datapeace/vms-web-models';
import {
  BUTTON_TEXT_MAX_LENGTH,
  ROUTES,
  getRestrictedVMSModeFromStorage,
  getSettingsFromLocalStorage,
  ISettings,
  saveSettingsIntoLocalStorage,
} from '@datapeace/vms-web-utils';
import {
  getRestrictedModeFromStorage,
  trimExtraText,
  REF_VMS_MODES,
} from '@datapeace/1up-frontend-shared-api';
import { useConfig } from '@datapeace/vms-web-hooks';

export const useWelcomeContainer = () => {
  const { setCurrentRoute } = useRouter();
  const { email } = useProfileContext();
  const {
    portalLogoUrl,
    checkinButtonLabel,
    checkoutButtonLabel,
    checkoutEnabled,
    portalTitle,
    visitInvitationEnabled,
    isMobilePlan,
    currentOrganization,
    visitorAppEnabled,
  } = useConfig();

  const { handleEnteredWelcomeScreen, setProcessData } =
    useProcessDataContext();
  let { vmsMode = REF_VMS_MODES.NORMAL_MODE } =
    getSettingsFromLocalStorage(email);
  const restrictedMode = getRestrictedModeFromStorage();
  const restrictedModeVMSMode = getRestrictedVMSModeFromStorage();
  const features = currentOrganization?.features;
  if (
    (vmsMode === 'auto_capture_mode_face' &&
      !features?.spacesVmsAppAutoCaptureMode) ||
    (vmsMode === 'contactless' && !features?.spacesVmsAppContactlessMode) ||
    (vmsMode === 'invited_only_qr' && !features?.spacesVmsAppInvitedOnlyMode) ||
    ((vmsMode === 'quick_mode_face' || vmsMode === 'quick_mode_mobile') &&
      !features?.spacesVmsAppQuickMode) ||
    (restrictedMode && restrictedModeVMSMode === 'normal_mode')
  ) {
    vmsMode = REF_VMS_MODES.NORMAL_MODE;
    saveSettingsIntoLocalStorage(email, {
      ...(getSettingsFromLocalStorage(email) as ISettings),
      vmsMode: REF_VMS_MODES.NORMAL_MODE,
    });
  }

  useEffect(() => handleEnteredWelcomeScreen(), [handleEnteredWelcomeScreen]);

  const handleSelectProcessType = (processType: ProcessType) => {
    setProcessData({ processType });
    if (isMobilePlan) {
      setCurrentRoute(ROUTES.CHECKIN_BY_MOBILE);
    } else {
      setCurrentRoute(ROUTES.CAPTURE);
    }
  };

  const handleQrButtonClick = () => {
    setProcessData({ isUsingQr: true });
    setCurrentRoute(ROUTES.CAPTURE);
  };

  useEffect(() => {
    if (vmsMode === 'contactless' && visitorAppEnabled) {
      setCurrentRoute(ROUTES.CONTACT_LESS_WELCOME);
      return;
    }

    if (
      (vmsMode === 'quick_mode_face' || vmsMode === 'quick_mode_mobile') &&
      isMobilePlan
    ) {
      setCurrentRoute(ROUTES.CHECKIN_BY_MOBILE);
      return;
    }

    if (
      (
        [
          REF_VMS_MODES.AUTO_CAPTURE_MODE_FACE,
          REF_VMS_MODES.QUICK_MODE_FACE,
          REF_VMS_MODES.QUICK_MODE_MOBILE,
          REF_VMS_MODES.INVITED_QR_ONLY,
        ] as string[]
      ).includes(vmsMode)
    ) {
      setProcessData({
        isUsingQr:
          vmsMode === REF_VMS_MODES.INVITED_QR_ONLY && visitInvitationEnabled,
      });
      setCurrentRoute(ROUTES.CAPTURE);
    }
  });

  return {
    portalTitle,
    portalLogoUrl,
    checkinButtonLabel: trimExtraText(
      checkinButtonLabel,
      BUTTON_TEXT_MAX_LENGTH
    ),
    checkoutButtonLabel: trimExtraText(
      checkoutButtonLabel,
      BUTTON_TEXT_MAX_LENGTH
    ),
    checkoutEnabled,
    handleCheckinSelect: () => handleSelectProcessType(ProcessType.Checkin),
    handleCheckoutSelect: () => handleSelectProcessType(ProcessType.Checkout),
    onSettingsButtonClick: () => setCurrentRoute(ROUTES.SETTINGS),
    onQrButtonClick: visitInvitationEnabled && handleQrButtonClick,
  };
};
