/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IPaginationResponse,
  ITemperatureDevice,
  ITemperatureRecord,
} from '../typings';
import { asyncDelay, convertToSnakeCase, createPath } from '../utils';
import { instance } from './client';
import {
  apiTemperatureDeviceNewRecordPath,
  apiTemperatureDevicePath,
  apiTemperatureDeviceRecordPath,
} from './constants';

export const waitForTemperatureRecord = async (
  temperatureDeviceId: number,
  timeoutInSeconds = 5
) => {
  if (timeoutInSeconds < 3) {
    throw new Error('temperature timeout should be atleast 3 seconds');
  }

  const startTime = new Date();
  const endTimeMs = +startTime + timeoutInSeconds * 1000;

  let lastException;

  // keep making api call till timed out
  while (Date.now() < endTimeMs) {
    try {
      // return with data if resolved successfully
      return await instance.client.get<{
        bodyTemp: string;
        bodyTempUnit: 'C' | 'F';
      }>(
        createPath(apiTemperatureDeviceNewRecordPath, {
          deviceId: temperatureDeviceId,
        }),
        { params: { since: startTime.toISOString() } }
      );
    } catch (err) {
      if ((err as any)?.response?.status !== 400) {
        throw err; // throw if statuscode not 400
      }

      // suppress and store error if status code is 400
      lastException = err;
    }

    await asyncDelay(2000);
  }

  // if timed out, throw last api error
  throw lastException;
};

export const getTemperatureDevice = async (
  deviceId: string
): Promise<ITemperatureDevice> => {
  const res = await instance.client.get<{ item: any }>(
    createPath(apiTemperatureDevicePath, { deviceId })
  );
  return res.item;
};

export const updateTemperatureDevice = (
  deviceId: string,
  data: Partial<ITemperatureDevice>
) =>
  instance.client.put<ITemperatureDevice>(
    createPath(apiTemperatureDevicePath, { deviceId }),
    convertToSnakeCase(data)
  );

export const removeTemperatureDevice = (deviceId: string) =>
  instance.client.delete<ITemperatureDevice>(
    createPath(apiTemperatureDevicePath, { deviceId })
  );

export const getRecordedTemperature = async (
  deviceId: number | null,
  params: Partial<{ measuredAtGte: string; measuredAtLte: string }>
) =>
  await instance.client.get<IPaginationResponse<ITemperatureRecord>>(
    createPath(apiTemperatureDeviceRecordPath, { deviceId }),
    {
      params: {
        measured_at__gte: params.measuredAtGte,
        measured_at__lte: params.measuredAtLte,
      },
    }
  );
