/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AxiosResponse } from 'axios';
import {
  IFormData,
  IPaginationParams,
  IPaginationResponse,
  IPerson,
  ISmartSearchSchema,
  SmartSearchParams,
  VaccinationVerificationData,
} from '../typings';
import {
  IPersonForFormSearch,
  IPersonGroupFilters,
  IPersonGroupFiltersForForm,
  IFace,
  IPersonDetailsForm,
  IScreeningData,
  IScreeningDataResponse,
  IPeopleFilters,
} from '../typings/people';
import {
  camelToSnakeCase,
  convertToSnakeCase,
  createPath,
  getCommonFileUploadConfig,
} from '../utils';
import { instance } from './client';
import {
  apiOrganizationPersonPath,
  apiPersonsPath,
  apiPeopleGroupsBulkPath,
  apiPersonFacesPath,
  apiOrganizationPersonFacesPath,
  apiBulkPersonFacesCreatePath,
  apiPersonFaceIndexBulkPath,
  apiPeopleFacesUnindexAllPath,
  apiPersonFacePath,
  apiOrganizationPeopleBulkValidateAbsolutePath,
  apiOrganizationPeopleBulkUploadPath,
  apiOrganizationPeopleBulkEmailPath,
  apiPersonDetailsByTokenPath,
  apiPersonFaceUploadByTokenPath,
  apiOrganizationPeopleBulkRemovePath,
  apiOrganizationPeopleBulkBlackListPath,
  apiOrganizationPeopleBulkSyncValidatPath,
  apiOrganizationPeopleBulkUploadSyncPath,
  apiOrganizationPeopleBulkScreeningFormPath,
  apiOrganizationScreeningTestPath,
  apiScreeningQuestionsByTokenPath,
  apiOrganizationPeopleSearchPath,
  apiPersonFileUploadByTokenPath,
  apiOrganizationPeopleSmartSearchPath,
  apiOrganizationPeopleFileUploadPath,
  apiOrganizationPeopleBulkDeleteValidatPath,
  apiOrganizationPeopleBulkDeletePath,
} from './constants';

// if groupIds passed in api is deleted, the api ignores the deleted groups and not gives error
export const getPeopleForFormSearch = async (
  organizationId: number,
  params?: IPaginationParams & Partial<IPersonGroupFiltersForForm>
) =>
  await instance.client.get<IPaginationResponse<IPersonForFormSearch>>(
    createPath(apiOrganizationPersonPath, { organizationId }),
    {
      params: convertToSnakeCase(params),
    }
  );

export const getPerson = async (personId: number) => {
  const res = await instance.client.get<{ item: IPerson }>(
    createPath(apiPersonsPath, { personId })
  );
  return res.item;
};

export const addFace = async (personId: number, faceImageUrl: string) => {
  await instance.client.post(
    createPath(apiPersonFacesPath, { personId }),
    convertToSnakeCase({ faceImageUrl })
  );
  return true;
};

export const getPeople = async (
  organizationId: number,
  params?: IPaginationParams &
    Partial<IPersonGroupFilters> &
    Partial<IPeopleFilters>
) => {
  const { c19VaccinationStatus, createdAtGte, createdAtLte, ...restParams } =
    params || {};
  return await instance.client.get<IPaginationResponse<IPerson>>(
    createPath(apiOrganizationPersonPath, { organizationId }),
    {
      params: {
        c19_vaccination_status: c19VaccinationStatus,
        ...convertToSnakeCase(restParams),
        created_at__gte: createdAtGte,
        created_at__lte: createdAtLte,
      },
    }
  );
};

export const addPerson = async (
  organizationId: number,
  data: Partial<IPerson>
) => {
  const res = await instance.client.post<{ item: IPerson }>(
    createPath(apiOrganizationPersonPath, { organizationId }),
    convertToSnakeCase(data)
  );
  return res.item;
};

export const updatePerson = async (
  personId: number,
  data: Partial<IPerson>
) => {
  const res = await instance.client.put<{ item: IPerson }>(
    createPath(apiPersonsPath, { personId }),
    convertToSnakeCase(data)
  );
  return res.item;
};

export const addPeopleToGroupsInBulk = async (
  organizationId: number,
  data: { people: number; peopleGroups: number[] }[]
) => {
  const res = await instance.client.post<{ items: IPerson[] }>(
    createPath(apiPeopleGroupsBulkPath, { organizationId }),
    convertToSnakeCase(data)
  );
  return res.items;
};

export const removePeopleFromGroupsInBulk = async (
  organizationId: number,
  data: { people: number; peopleGroups: number[] }[]
) => {
  const res: { items: IPerson[] } = await instance.client.delete(
    createPath(apiPeopleGroupsBulkPath, { organizationId }),
    {
      data: convertToSnakeCase(data),
    }
  );
  return res.items;
};

export const removePerson = async (personId: number) => {
  await instance.client.delete(createPath(apiPersonsPath, { personId }));
  return true;
};

export const getFaces = async (
  personId: number,
  params?: IPaginationParams & {
    isIndexed?: boolean;
    createdAtGte?: string;
    createdAtLte?: string;
    sortField?: string;
    sortOrder?: 'ascend' | 'descend';
  }
) => {
  const res = await instance.client.get<IPaginationResponse<IFace>>(
    createPath(apiPersonFacesPath, { personId }),
    {
      params: params && {
        page: params.page,
        is_indexed: params.isIndexed,
        ordering: params.sortField
          ? `${params.sortOrder === 'descend' ? '-' : ''}${camelToSnakeCase(
              params.sortField
            )}`
          : undefined,
        page_size: params.pageSize,
        created_at__gte: params.createdAtGte,
        created_at__lte: params.createdAtLte,
      },
    }
  );
  return { faces: res.items, count: res.count };
};

export const getUploadFaceConfig = (organizationId: number) => ({
  ...getCommonFileUploadConfig(),
  action: createPath(apiOrganizationPersonFacesPath, { organizationId }),
});

export const uploadPeopleFace = async (
  organizationId: number,
  faceUrl: string | FormData,
  configData?: {
    updateProgressConfig?: {
      photoUid: string;
      updateProgressCallback: (uid: string, progress: number) => void;
    };
  }
): Promise<AxiosResponse> =>
  await instance.client.post(
    createPath(apiOrganizationPersonFacesPath, { organizationId }),
    faceUrl instanceof FormData ? faceUrl : convertToSnakeCase({ faceUrl }),
    {
      onUploadProgress: (progressEvent) => {
        if (
          configData?.updateProgressConfig?.photoUid &&
          configData.updateProgressConfig.updateProgressCallback
        ) {
          configData.updateProgressConfig.updateProgressCallback(
            configData.updateProgressConfig.photoUid,
            //@ts-ignore
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        }
      },
    }
  );

export const addFacesBulk = async (
  personId: number,
  faces: {
    faceImageUrl: string;
  }[]
) => {
  const res = await instance.client.post<{
    items: {
      faceImageUrl: string;
    }[];
    errors: any;
  }>(
    createPath(apiBulkPersonFacesCreatePath, { personId }),
    convertToSnakeCase({ items: faces })
  );
  return res;
};

export const removeFacesBulk = async (personId: number, faces: string[]) => {
  await instance.client.delete(
    createPath(apiBulkPersonFacesCreatePath, { personId }),
    { data: { items: faces } }
  );
  return true;
};

export const indexFacesBulk = async (
  personId: number,
  items: number[],
  indexType: string
) => {
  await instance.client.post(
    createPath(apiPersonFaceIndexBulkPath, { personId, indexType }),
    { items: items }
  );
  return true;
};

export const unindexAllFacesBulk = async (
  organizationId: number,
  peopleIds: number[]
) => {
  await instance.client.put(
    createPath(apiPeopleFacesUnindexAllPath, { organizationId }),
    { items: peopleIds }
  );
  return true;
};

export const removeFace = async (peopleId: number, faceId: number) => {
  await instance.client.delete(
    createPath(apiPersonFacePath, { peopleId, faceId })
  );
  return true;
};

export const getBulkValidateFaceConfig = (organizationId: number) => ({
  ...getCommonFileUploadConfig(),
  action: createPath(apiOrganizationPeopleBulkValidateAbsolutePath, {
    organizationId,
  }),
});

export const uploadPeopleBulk = async (
  organizationId: number,
  peoples: any[]
) => {
  const res = await instance.client.post(
    createPath(apiOrganizationPeopleBulkUploadPath, { organizationId }),
    {
      data: convertToSnakeCase(peoples),
    }
  );
  return !!res;
};

export const sendEmailToPeople = async (
  organizationId: number,
  data: { items: number[] }
) => {
  const res = await instance.client.post(
    createPath(apiOrganizationPeopleBulkEmailPath, { organizationId }),
    convertToSnakeCase(data)
  );
  return !!res;
};
export const getPeopleDetailsByToken = async (token: string) =>
  await instance.client.get<null, IPersonDetailsForm>(
    createPath(apiPersonDetailsByTokenPath, { token }),
    {
      // @ts-ignore
      noAuth: true,
    }
  );

export const updatePeopleDetailsByToken = async (
  token: string,
  data: Partial<IPersonDetailsForm>
) =>
  await instance.client.put<null, IPersonDetailsForm>(
    createPath(apiPersonDetailsByTokenPath, { token }),
    convertToSnakeCase(data),
    {
      // @ts-ignore
      noAuth: true,
    }
  );

export const uploadPersonFaceByTokenConfig = (token: string) => ({
  ...getCommonFileUploadConfig(),
  action: createPath(apiPersonFaceUploadByTokenPath, { token }),
});

export const uploadPersonFaceByToken = async (
  token: string,
  faceUrl: string | FormData
) =>
  await instance.client.post<null, { faceImageUrl: string }>(
    createPath(apiPersonFaceUploadByTokenPath, { token }),
    faceUrl,
    {
      // @ts-ignore
      noAuth: true,
    }
  );

export const removePeopleBulk = async (
  organizationId: number,
  data: { items: number[] }
) => {
  const res = await instance.client.delete(
    createPath(apiOrganizationPeopleBulkRemovePath, { organizationId }),
    {
      data: convertToSnakeCase(data),
    }
  );
  return !!res;
};

export const updateBlacklistPeopleBulk = async (
  organizationId: number,
  data: { items: number[]; blacklist: boolean }
) => {
  const res = await instance.client.put(
    createPath(apiOrganizationPeopleBulkBlackListPath, { organizationId }),
    convertToSnakeCase(data)
  );
  return !!res;
};

export const getPeopleBulkSyncValidate = (
  organizationId: number,
  compressAllTypes = false
) => ({
  ...getCommonFileUploadConfig(
    'post',
    { convertResponseToCamelCase: false },
    compressAllTypes
  ),
  action: createPath(apiOrganizationPeopleBulkSyncValidatPath, {
    organizationId,
  }),
});

export const uploadPeopleBulkInSync = async (
  organizationId: number,
  data: { items: any[]; peoplePkField: string }
) => {
  const res = await instance.client.post(
    createPath(apiOrganizationPeopleBulkUploadSyncPath, { organizationId }),
    convertToSnakeCase(data, ['items'])
  );
  return !!res;
};

export const sendScreeningFormToPeople = async (
  organizationId: number,
  data: { items: number[] }
) => {
  const res = await instance.client.post(
    createPath(apiOrganizationPeopleBulkScreeningFormPath, { organizationId }),
    convertToSnakeCase(data)
  );
  return !!res;
};

export const sendScreeningForm = async ({
  organizationId,
  params,
}: {
  organizationId: string;
  params: {
    persons: number[];
    personGroups: number[];
    sendSms: boolean;
    sendEmail: boolean;
  };
}) => {
  const { persons, personGroups, sendEmail, sendSms } = params;
  const res = await instance.client.post(
    createPath(apiOrganizationScreeningTestPath, { organizationId }),
    convertToSnakeCase({
      target: { persons, personGroups },
      sendEmail,
      sendSms,
    })
  );
  return !!res;
};

// people screening related functions
export const getScreeningDataByToken = async (token: string) =>
  await instance.client.get<null, IScreeningData>(
    createPath(apiScreeningQuestionsByTokenPath, { token }),
    {
      // @ts-ignore
      noAuth: true,
    }
  );

export const updateScreeningDataByToken = async (
  token: string,
  data: { formData: IFormData } & Partial<VaccinationVerificationData>
) =>
  await instance.client.put<null, IScreeningDataResponse>(
    createPath(apiScreeningQuestionsByTokenPath, { token }),
    convertToSnakeCase(data),
    {
      // @ts-ignore
      noAuth: true,
    }
  );

export const getPeopleByKeys = async (
  organizationId: number,
  data: { items: string[]; peoplePkField: string }
) => {
  const res = await instance.client.post<{
    items: IPerson[];
    missing: { [key: string]: boolean };
  }>(
    createPath(apiOrganizationPeopleSearchPath, { organizationId }),
    convertToSnakeCase(data)
  );
  return res;
};

export const uploadPersonImageByToken = async (
  token: string,
  fileUrl: string | FormData
) =>
  await instance.client.post<null, { fileUrl: string }>(
    createPath(apiPersonFileUploadByTokenPath, { token }),
    fileUrl,
    {
      // @ts-ignore
      noAuth: true,
    }
  );

export const uploadPersonFileByToken = async (
  token: string,
  file: File,
  configData?: {
    updateProgressConfig?: {
      photoUid: string;
      updateProgressCallback: (uid: string, progress: number) => void;
    };
  }
) => {
  try {
    const form = new FormData();
    form.append('file', file, file.name);
    const response = await instance.client.post<null, { fileUrl: string }>(
      createPath(apiPersonFileUploadByTokenPath, { token }),
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        // @ts-ignore
        noAuth: true,
        onUploadProgress: (progressEvent) => {
          if (
            configData?.updateProgressConfig?.photoUid &&
            configData.updateProgressConfig.updateProgressCallback
          ) {
            configData.updateProgressConfig.updateProgressCallback(
              configData.updateProgressConfig.photoUid,
              //@ts-ignore
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          }
        },
      }
    );
    return response.fileUrl;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const getPeopleSmartSearchSchema = async (organizationId: number) => {
  const res = await instance.client.get<ISmartSearchSchema>(
    createPath(apiOrganizationPeopleSmartSearchPath, { organizationId })
  );
  return res;
};

export const getPeopleSmartSearch = async (
  organizationId: number,
  params: SmartSearchParams,
  paginationParams: IPaginationParams
) => {
  const res = await instance.client.post(
    createPath(apiOrganizationPeopleSmartSearchPath, { organizationId }),
    params,
    {
      params: convertToSnakeCase(paginationParams),
    }
  );
  return res;
};

export const uploadPeopleFile = async (
  organizationId: number,
  file: File,
  configData?: {
    updateProgressConfig?: {
      photoUid: string;
      updateProgressCallback: (uid: string, progress: number) => void;
    };
  }
) => {
  try {
    const form = new FormData();
    form.append('file', file, file.name);
    const response = await instance.client.post<{ fileUrl: string }>(
      createPath(apiOrganizationPeopleFileUploadPath, { organizationId }),
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          if (
            configData?.updateProgressConfig?.photoUid &&
            configData.updateProgressConfig.updateProgressCallback
          ) {
            configData.updateProgressConfig.updateProgressCallback(
              configData.updateProgressConfig.photoUid,
              //@ts-ignore
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          }
        },
      }
    );

    return response.fileUrl;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

export const deletePeopleBulk = async (
  organizationId: number,
  data: { items: any[]; peoplePkField: string }
) => {
  const res = await instance.client.post(
    createPath(apiOrganizationPeopleBulkDeletePath, { organizationId }),
    convertToSnakeCase(data, ['items'])
  );
  return !!res;
};

export const validatePeopleBulkDeleteFile = (
  organizationId: number,
  compressAllTypes = false
) => ({
  ...getCommonFileUploadConfig(
    'post',
    { convertResponseToCamelCase: false },
    compressAllTypes
  ),
  action: createPath(apiOrganizationPeopleBulkDeleteValidatPath, {
    organizationId,
  }),
});
