/* eslint-disable @typescript-eslint/ban-ts-comment */
import { IProfile } from '../typings';
import {
  convertToCamelCase,
  convertToSnakeCase,
  createPath,
  getCommonFileUploadConfig,
} from '../utils';
import { instance } from './client';
import {
  apiChangePassword,
  apiProfilePath,
  apiProfilePictureUploadAbsolutePath,
  apiProfilePictureUploadPath,
  apiScreenSaverImagesUploadAbsolutePath,
} from './constants';

export const getProfile = async (): Promise<IProfile> => {
  const res = await instance.client.get<IProfile>(createPath(apiProfilePath), {
    // @ts-ignore
    convertResponseToCamelCase: false,
  });
  return convertToCamelCase(res, ['web_client'], true);
};

export const updateProfile = async (profileData: Partial<IProfile>) => {
  const res = await instance.client.put<IProfile>(
    createPath(apiProfilePath),
    convertToSnakeCase(profileData, ['webClient'], true),
    {
      // @ts-ignore
      convertResponseToCamelCase: false,
    }
  );
  return convertToCamelCase(res, ['web_client'], true);
};

export const uploadProfilePictureConfig = (
  isDownscaleAllowed = true,
  imageQuality?: number,
  compressAllTypes = false
) => ({
  ...getCommonFileUploadConfig(
    'put',
    undefined,
    compressAllTypes,
    isDownscaleAllowed,
    imageQuality
  ),
  action: createPath(apiProfilePictureUploadAbsolutePath),
});

export const uploadScreenSaverImagesConfig = (
  spaceId: string,
  compressAllTypes = false
) => ({
  ...getCommonFileUploadConfig('post', undefined, compressAllTypes, false),
  action: createPath(apiScreenSaverImagesUploadAbsolutePath, { spaceId }),
});

export const changeMyPassword = async (
  currPassword: string,
  newPassword: string
) => {
  const data = {
    currPassword,
    newPassword,
  };
  await instance.client.put(
    createPath(apiChangePassword),
    convertToSnakeCase(data)
  );
  return true;
};

export const uploadProfilePicture = async (file: Blob) => {
  try {
    const form = new FormData();

    // suppose type is application/png, then fileName will be file.png
    const fileName = `file.${file.type?.split('/')?.[1] || 'jpeg'}`;

    form.append('file', file, fileName);
    const { item } = await instance.client.put<{
      item: {
        avatarUrl: string;
        profileImageUrl: string;
      };
    }>(createPath(apiProfilePictureUploadPath), form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return item.avatarUrl || item.profileImageUrl;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
