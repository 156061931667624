import { useState, useEffect, useCallback } from 'react';
import { IErrorResponse, message } from '@datapeace/1up-frontend-web-ui';
import {
  useProcessDataContext,
  useRouter,
  useProfileContext,
} from '@datapeace/vms-web-models';
import {
  getSettingsFromLocalStorage,
  ROUTES,
  Api,
  TEMPERATURE_FETCH_DEFAULT_TIME,
} from '@datapeace/vms-web-utils';
import { useIsMounted } from '@datapeace/1up-frontend-web-utils';
import { useConfig } from '@datapeace/vms-web-hooks';

export function useTemperatureContainer() {
  const { email } = useProfileContext();
  const { setCurrentRoute } = useRouter();
  const { currentOrganization } = useConfig();
  const { setProcessData, isTemperatureRequired, getBackNavigationRoute } =
    useProcessDataContext();
  const [status, setStatus] = useState<
    'idle' | 'loading' | 'success' | 'error'
  >('idle');
  const [statusMessage, setStatusMessage] = useState('');

  const isMountedRef = useIsMounted();

  const {
    temperatureFetchMaxTime = TEMPERATURE_FETCH_DEFAULT_TIME,
    vmsMode,
    temperatureDeviceId,
  } = getSettingsFromLocalStorage(email);

  const fetchTemperature = useCallback(async () => {
    setStatus('loading');
    setStatusMessage('Waiting for temperature...');
    try {
      if (!temperatureDeviceId) {
        throw new Error('Temperature device not configured!');
      }
      const res = await Api.waitForTemperatureRecord(
        temperatureDeviceId,
        +temperatureFetchMaxTime
      );
      if (!isMountedRef.current) return;
      setStatus('success');
      setStatusMessage(
        `Temperature Recorded Successfully: ${res.bodyTemp}\xB0${res.bodyTempUnit}`
      );
      setProcessData({
        bodyTemp: res.bodyTemp,
        bodyTempUnit: res.bodyTempUnit,
      });
      setCurrentRoute(ROUTES.CHECK_IN_SUCCESS);
    } catch (err) {
      if (!isMountedRef.current) return;
      if (vmsMode === 'auto_capture_mode_face' || vmsMode === 'contactless') {
        // in autocapture show error message and redirect to next screen
        message.error((err as IErrorResponse).message);
        setCurrentRoute(ROUTES.CHECK_IN_SUCCESS);
        return;
      }

      setStatus('error');
      setStatusMessage((err as IErrorResponse).message);
    }
  }, [
    temperatureDeviceId,
    temperatureFetchMaxTime,
    isMountedRef,
    setProcessData,
    setCurrentRoute,
    vmsMode,
  ]);

  useEffect(() => {
    if (
      isTemperatureRequired &&
      currentOrganization?.features?.spacesVisitorBodyTemperature
    ) {
      fetchTemperature();
    } else {
      setCurrentRoute(ROUTES.CHECK_IN_SUCCESS);
    }
  }, [
    isTemperatureRequired,
    setCurrentRoute,
    fetchTemperature,
    currentOrganization,
  ]);

  function handleGoBack() {
    setCurrentRoute(getBackNavigationRoute(ROUTES.TERMS));
  }

  return {
    status,
    message: statusMessage,
    onSubmit: () => setCurrentRoute(ROUTES.CHECK_IN_SUCCESS),
    fetchTemperature,
    handleGoBack,
  };
}
