import React, { FC } from 'react';
import {
  CheckCircleOutlined,
  LogoutOutlined,
  SettingOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import { Button } from '@datapeace/1up-frontend-web-ui';
import { useWelcomeContainer } from '@datapeace/vms-web-containers';
import {
  Layout,
  Content,
  Footer,
  ImageLoader,
  Screensaver,
  HomeSpaceSelectionDropDown,
} from '@datapeace/vms-web-components';
import {
  BRAND_NAME,
  getSettingsFromLocalStorage,
} from '@datapeace/vms-web-utils';
import { useProfileContext } from '@datapeace/vms-web-models';
import { useConfig } from '@datapeace/vms-web-hooks';
import {
  getRestrictedModeFromStorage,
  REF_VMS_MODES,
} from '@datapeace/1up-frontend-shared-api';
import styles from './welcome-screen.module.scss';

export const Welcome: FC = () => {
  const {
    portalLogoUrl,
    checkinButtonLabel,
    checkoutButtonLabel,
    checkoutEnabled,
    handleCheckinSelect,
    handleCheckoutSelect,
    portalTitle,
    onSettingsButtonClick,
    onQrButtonClick,
  } = useWelcomeContainer();
  const profile = useProfileContext();
  const config = useConfig();
  const { visitInvitationEnabled } = config;

  const settings = getSettingsFromLocalStorage(profile.email);
  const { displaySpaceSelectionOnHomeScreen, vmsMode } = settings;
  const isRestrictedMode = getRestrictedModeFromStorage();
  const showHomeSpaceSelectionDropDown =
    !isRestrictedMode &&
    vmsMode === REF_VMS_MODES.NORMAL_MODE &&
    displaySpaceSelectionOnHomeScreen;
  return (
    <Layout showBackground>
      {showHomeSpaceSelectionDropDown && <HomeSpaceSelectionDropDown />}
      <Screensaver />
      <Content>
        <div className={styles.Root}>
          {portalTitle && <h2 className={styles.Title}>{portalTitle}</h2>}
          {portalLogoUrl && (
            <div className={styles.BrandImage}>
              <ImageLoader src={portalLogoUrl} fit="contain" />
            </div>
          )}

          <div className={styles.ButtonsContainer}>
            <Button onClick={handleCheckinSelect} type="primary">
              {checkinButtonLabel || 'Check-in'}
              <CheckCircleOutlined />
            </Button>

            {checkoutEnabled && (
              <Button onClick={handleCheckoutSelect}>
                {checkoutButtonLabel || 'Check-out'}
                <LogoutOutlined />
              </Button>
            )}
          </div>

          {onQrButtonClick && visitInvitationEnabled && (
            <div className={styles.ButtonsContainer}>
              <Button onClick={onQrButtonClick} className={styles.QrButton}>
                <QrcodeOutlined className={styles.QrButtonIcon} />
                Use QR
              </Button>
            </div>
          )}
        </div>
      </Content>

      <Footer>
        <div>
          <Button
            icon={<SettingOutlined />}
            shape="circle"
            onClick={onSettingsButtonClick}
          />
          <span
            style={{ marginLeft: '10px' }}
          >{`Powered by ${BRAND_NAME}`}</span>
        </div>
      </Footer>
    </Layout>
  );
};
