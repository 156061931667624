import { FC, FormEvent } from 'react';
import { Button } from '@datapeace/1up-frontend-web-ui';
import { ArrowRightOutlined, SettingOutlined } from '@ant-design/icons';
import { useCheckinByMobileContainer } from '@datapeace/vms-web-containers';
import {
  Layout,
  Header,
  Content,
  Footer,
  NavButtons,
  Form,
  DynamicForm,
} from '@datapeace/vms-web-components';
import {
  BRAND_NAME,
  ROUTES,
  getSettingsFromLocalStorage,
} from '@datapeace/vms-web-utils';
import styles from '../custom-form-screen/custom-form-screen.module.scss';
import { useProfileContext } from '@datapeace/vms-web-models';
import { useConfig } from '@datapeace/vms-web-hooks';

export const CheckinByMobile: FC = () => {
  const {
    formSchema,
    formData,
    setFormData,
    isLoading,
    onSubmit,
    onSettingsButtonClick,
  } = useCheckinByMobileContainer();

  const { email } = useProfileContext();
  const config = useConfig();

  const { vmsMode } = getSettingsFromLocalStorage(email);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Layout>
      <Header />
      <Content>
        <div className={styles.Form}>
          <Form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <DynamicForm
              values={formData}
              items={formSchema}
              onChange={setFormData}
            />
            {(vmsMode === 'quick_mode_face' ||
              vmsMode === 'quick_mode_mobile') && (
              <Button
                loading={isLoading}
                // onClick={onNext}
                size="large"
                htmlType="submit"
                type="primary"
              >
                Continue
                <ArrowRightOutlined />
              </Button>
            )}
            {vmsMode !== 'quick_mode_face' &&
              vmsMode !== 'quick_mode_mobile' && (
                <NavButtons
                  loading={isLoading}
                  backLink={config.isMobilePlan ? ROUTES.HOME : ROUTES.REGISTER}
                />
              )}
          </Form>
        </div>
      </Content>

      {!!onSettingsButtonClick && (
        <Footer>
          <div>
            <Button
              icon={<SettingOutlined />}
              shape="circle"
              onClick={onSettingsButtonClick}
            />
            <span>{`Powered by ${BRAND_NAME}`}</span>
          </div>
        </Footer>
      )}
    </Layout>
  );
};
