import { useEffect } from 'react';
import { useRouter, useProfileContext } from '@datapeace/vms-web-models';
import { getSettingsFromLocalStorage, ROUTES } from '@datapeace/vms-web-utils';
import { useCountdown } from '@datapeace/1up-frontend-web-utils';

export function useRedirectOnDone(
  status: 'success' | 'error' | 'idle' | 'loading'
) {
  const { setCurrentRoute } = useRouter();
  const profile = useProfileContext();
  const { successScreenAutoCloseTimeout, vmsMode, successScreenAutoClose } =
    getSettingsFromLocalStorage(profile.email);

  // redirect on success
  // if vmsMode is autocapture or contactless, then redirect on error too
  // if successScreenAutoClose is false then don't redirect user, but if vmsMode is autocapture or contactless, then redirect on successScreenAutoClose false also.
  const shouldRedirect = successScreenAutoClose
    ? status === 'success' ||
      ((vmsMode === 'auto_capture_mode_face' || vmsMode === 'contactless') &&
        status === 'error')
    : false || vmsMode === 'contactless';

  // after 3 seconds of successful checkin/checkout redirect to home
  const [resendDelay] = useCountdown(
    successScreenAutoCloseTimeout || 3,
    shouldRedirect ? 1000 : null
  );
  useEffect(() => {
    if (shouldRedirect && resendDelay === 0) {
      setCurrentRoute(ROUTES.HOME);
    }
  }, [shouldRedirect, resendDelay, setCurrentRoute]);

  return [resendDelay, shouldRedirect];
}
