import {
  IVisitorItemByMobileNumber,
  REF_VMS_MODES,
} from '@datapeace/1up-frontend-shared-api';
import { useCallback, useMemo } from 'react';
import { IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import {
  ProcessType,
  useProcessDataContext,
  useProfileContext,
  useRouter,
} from '@datapeace/vms-web-models';
import {
  Api,
  getSettingsFromLocalStorage,
  ROUTES,
} from '@datapeace/vms-web-utils';
import { useConfig } from '@datapeace/vms-web-hooks';

export function useVisitorSelectionContainer() {
  const { setCurrentRoute } = useRouter();
  const {
    setProcessData,
    availableVisitorListByMobileNumber,
    handleSelectedDifferentPerson,
    processType,
  } = useProcessDataContext();
  const { email } = useProfileContext();
  const { isMobilePlan, currentSpace } = useConfig();
  const { vmsMode } = getSettingsFromLocalStorage(email);

  const handleSelectVisitor = useCallback(
    async (visitor: IVisitorItemByMobileNumber) => {
      handleSelectedDifferentPerson();
      if (isMobilePlan) {
        let currentProcessType = processType;

        if (!currentSpace) {
          throw new Error('Space is not selected!');
        }
        const {
          item: { activeVisit, lastVisit },
        } = await Api.getSpaceVisitor({
          spaceId: currentSpace.id,
          visitorId: visitor?.id,
        });
        if (
          vmsMode === REF_VMS_MODES.QUICK_MODE_FACE ||
          vmsMode === REF_VMS_MODES.QUICK_MODE_MOBILE
        ) {
          currentProcessType = activeVisit
            ? ProcessType.Checkout
            : ProcessType.Checkin;
        }
        setProcessData({
          processType: currentProcessType,
          activeVisit,
          lastVisit,
        });
      }
      setProcessData({
        notYouRegisterData: { ...visitor },
        personData: visitor,
        registerData: null,
      });
      setCurrentRoute(ROUTES.REGISTER);
    },
    [
      setCurrentRoute,
      setProcessData,
      handleSelectedDifferentPerson,
      currentSpace,
      isMobilePlan,
      processType,
      vmsMode,
    ]
  );

  const handleGoBack = useCallback(() => {
    setProcessData({
      availableVisitorListByMobileNumber: null,
      notYouRegisterData: null,
    });
    setCurrentRoute(ROUTES.CHECKIN_BY_MOBILE);
  }, [setCurrentRoute, setProcessData]);

  const mobileNumber = useMemo(
    () =>
      (availableVisitorListByMobileNumber &&
        availableVisitorListByMobileNumber?.mobile) ||
      '',
    [availableVisitorListByMobileNumber]
  );

  const handlContinueAsNewVisitor = useCallback(() => {
    try {
      if (processType === ProcessType.Checkout)
        throw new Error('You are not checked in!');
      handleSelectedDifferentPerson();
      setProcessData({
        registerData: { firstName: '', lastName: '', mobileNumber },
      });
      setCurrentRoute(ROUTES.REGISTER);
    } catch (error) {
      toast.error(error as IErrorResponse);
    }
  }, [
    setCurrentRoute,
    setProcessData,
    handleSelectedDifferentPerson,
    mobileNumber,
    processType,
  ]);

  return {
    visitorsOption: availableVisitorListByMobileNumber?.visitors,
    mobileNumber,
    handleSelectVisitor,
    handleGoBack,
    handlContinueAsNewVisitor,
  };
}
