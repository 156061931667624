import { useState, useEffect, FC } from 'react';
import { Button, Loader, StatusCard } from '@datapeace/1up-frontend-web-ui';
import {
  IProfile,
  REF_VMS_MODES,
  clearSearchParams,
  getSearchParamFromUrl,
} from '@datapeace/1up-frontend-shared-api';
import * as Sentry from '@sentry/react';
import { ProfileProvider } from '@datapeace/vms-web-models';
import {
  redirectToLogin,
  getTokensFromUrl,
  onLogout,
  VMS_LOGGED_OUT_EVENT,
  Api,
  setLogoutRedirectUrlInStorage,
  getAccessTokenFromStorage,
  setTokensInStorage,
  setRestrictedVMSModeInStorage,
} from '@datapeace/vms-web-utils';
import {
  setCurrentOrganizationInStorage,
  setCurrentSpaceInStorage,
  setRestrictedModeInStorage,
} from '@datapeace/1up-frontend-shared-api';

function LoginAlert() {
  return (
    <div style={{ display: 'flex', flex: 1, backgroundColor: '#f7f7f7' }}>
      <div style={{ margin: 'auto', maxWidth: 450 }}>
        <StatusCard
          actions={[
            <Button
              onClick={() => redirectToLogin()}
              size="large"
              type="primary"
            >
              Login Again
            </Button>,
          ]}
          status="error"
          message="Session Expired!"
        >
          In case you are a visitor, please ask for the assistance from the
          concerned person in order to complete the process.
        </StatusCard>
      </div>
    </div>
  );
}

function RedirectToLogin() {
  useEffect(() => {
    redirectToLogin();
  }, []);

  return null;
}

export const Authenticator: FC<{ children: JSX.Element }> = ({ children }) => {
  const [profile, setProfile] = useState<IProfile | null>(null);
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  // save token if found in URL
  const { accessToken, refreshToken } = getTokensFromUrl();
  if (accessToken && typeof accessToken === 'string') {
    setTokensInStorage(accessToken, refreshToken);
  }

  const tokenFromStorage = getAccessTokenFromStorage();

  useEffect(() => {
    Sentry.configureScope((scope: any) => {
      scope.setUser({
        ...scope.getUser(),
        email: profile?.email,
      });
    });
  }, [profile]);

  // handles the logout event
  useEffect(() => {
    const handleLogout = async () => {
      await onLogout();
      setIsLoggedOut(true);
    };

    window.addEventListener(VMS_LOGGED_OUT_EVENT, handleLogout);
    return () => window.removeEventListener(VMS_LOGGED_OUT_EVENT, handleLogout);
  }, []);

  // fetches profile data if token is set
  useEffect(() => {
    setProfile(null);

    if (!tokenFromStorage) {
      return;
    }

    const fetchProfileData = async () => {
      try {
        const profileData = await Api.getProfile();
        setProfile(profileData);
        const restrictedMode = getSearchParamFromUrl('restricted_mode') as
          | '1'
          | '0'
          | undefined;
        if (restrictedMode === '1') {
          const orgId = getSearchParamFromUrl('org') as string | undefined;
          const spaceId = getSearchParamFromUrl('space') as string | undefined;
          const logoutRedirectUrl = getSearchParamFromUrl('logout_redirect') as
            | string
            | undefined;
          const restrictedModeVMSMode = getSearchParamFromUrl('vms_mode') as
            | ValueOf<typeof REF_VMS_MODES>
            | undefined;
          if (restrictedModeVMSMode) {
            setRestrictedVMSModeInStorage(restrictedModeVMSMode);
          }
          if (orgId && spaceId) {
            // Store this config in local storage
            setCurrentOrganizationInStorage(profileData.email, +orgId);
            setCurrentSpaceInStorage(profileData.email, +spaceId);
            !!logoutRedirectUrl &&
              setLogoutRedirectUrlInStorage(logoutRedirectUrl);
            setRestrictedModeInStorage('1');
          }
        }
      } catch (error) {
        console.error(error);
        setIsLoggedOut(true);
      } finally {
        clearSearchParams();
      }
    };

    fetchProfileData();
  }, [tokenFromStorage]);

  if (isLoggedOut) {
    return <LoginAlert />;
  }

  if (!tokenFromStorage) {
    return <RedirectToLogin />;
  }

  if (profile) {
    return <ProfileProvider value={profile}>{children}</ProfileProvider>;
  }

  return <Loader />;
};
