import { AxiosResponse } from 'axios';
import {
  IPrinter,
  IOrganizationDetailed,
  IOrganization,
  ISpace,
  IPaginationParams,
  IGroup,
  IPaginationResponse,
  IOrganizationConfig,
  IOrganizationScreeningConfig,
  IScreening,
  ITemperatureDevice,
  IPendingInvite,
  IOnBoardingDetails,
  IOrganizationMember,
} from '../typings';
import {
  convertToSnakeCase,
  createPath,
  getCommonFileUploadConfig,
} from '../utils';
import { instance } from './client';
import {
  apiOrganizationPath,
  apiOrganizationsPath,
  apiOrganizationConfigPath,
  apiMyOrganizationsPath,
  apiOrganizationSpacesPath,
  apiOrganizationMemberPath,
  apiOrganizationMembersPath,
  apiOrganizationsInviteAcceptPath,
  apiOrganizationPeopleGroupsPath,
  apiOrganizationPrintersPath,
  apiOrganizationLogoUploadPath,
  apiOrganizationScreeningPath,
  apiOrganizationScreeningTestPath,
  apiScreeningDetailPath,
  apiOrganizationTemperatureDevicesPath,
  apiOrganizationBulkDeactivateMembersPath,
  apiOrganizationBulkActivateMembersPath,
} from './constants';
import { snakeCase } from 'lodash-es';

export const getMyOrganizations = async () => {
  const res = await instance.client.get<{
    items: IOrganizationDetailed[];
    pendingInvitations: IPendingInvite[];
  }>(createPath(apiMyOrganizationsPath));
  return res;
};

export const getOrganizationConfig = async (organizationId: number) =>
  instance.client.get<IOrganizationConfig>(
    createPath(apiOrganizationConfigPath, { organizationId })
  );

export const uploadOrganizationLogoConfig = (
  organizationId: string,
  assetIntent: string,
  isDownscaleAllowed = false,
  compressAllTypes = false
) => ({
  ...getCommonFileUploadConfig(
    'post',
    undefined,
    compressAllTypes,
    isDownscaleAllowed
  ),
  action: createPath(apiOrganizationLogoUploadPath, { organizationId }),
  data: {
    additionalFields: [
      {
        fileName: 'asset_intent',
        data: assetIntent,
      },
    ],
  },
});

export const createOrganization = async (data: IOnBoardingDetails) => {
  const res = await instance.client.post<{ item: IOrganization }>(
    createPath(apiOrganizationsPath),
    convertToSnakeCase(data)
  );
  return res.item;
};

export const getOrganization = async (organizationId: string) => {
  const res = await instance.client.get<{ item: IOrganization }>(
    createPath(apiOrganizationPath, { organizationId })
  );
  return res.item;
};

export const updateOrganizationConfig = async (
  organizationId: number,
  data: Partial<IOrganizationConfig>
) =>
  instance.client.put<IOrganizationConfig>(
    createPath(apiOrganizationConfigPath, { organizationId }),
    convertToSnakeCase(data)
  );

export const updateOrganization = async (
  organizationId: string,
  data: Partial<IOrganization>
) => {
  const res = await instance.client.put<{ item: IOrganization }>(
    createPath(apiOrganizationPath, { organizationId }),
    convertToSnakeCase(data)
  );
  return res.item;
};

export const getOrganizationSpaces = async (
  organizationId: number,
  params?: IPaginationParams & {
    active?: number;
  }
) =>
  await instance.client.get<IPaginationResponse<ISpace>>(
    createPath(apiOrganizationSpacesPath, { organizationId }),
    convertToSnakeCase({ params })
  );

export const createSpaceInOrganization = async (
  organizationId: string,
  data: Partial<ISpace> & { name: string }
) => {
  const res = await instance.client.post<{ item: ISpace }>(
    createPath(apiOrganizationSpacesPath, { organizationId }),
    convertToSnakeCase(data)
  );
  return res.item;
};

export const getOrganizationMembers = async (
  organizationId: string,
  params?: IPaginationParams
): Promise<AxiosResponse> =>
  await instance.client.get(
    createPath(apiOrganizationMembersPath, { organizationId }),
    convertToSnakeCase({ params })
  );

export const createOrganizationMember = async (
  organizationId: string,
  data: Omit<IOrganizationMember, 'spaces'> & { spaces: number[] }
) => {
  const res = await instance.client.post<{ item: IOrganizationMember }>(
    createPath(apiOrganizationMembersPath, { organizationId }),
    convertToSnakeCase(data)
  );
  return res.item;
};

export const getSpecificMember = async (
  organizationId: string,
  memberId: string
) => {
  const res = await instance.client.get<{ item: IOrganizationMember }>(
    createPath(apiOrganizationMemberPath, { organizationId, memberId })
  );
  return res.item;
};

export const updateOrganizationMember = async (
  organizationId: string,
  memberId: string,
  data: Partial<Omit<IOrganizationMember, 'spaces'>> & {
    reinvite?: boolean;
    spaces?: number[];
  }
): Promise<AxiosResponse> => {
  const res = await instance.client.put<{ item: IOrganizationMember }>(
    createPath(apiOrganizationMemberPath, { organizationId, memberId }),
    convertToSnakeCase(data)
  );
  return res.item;
};

export const deleteOrganizationMember = (
  organizationId: string,
  memberId: string
): Promise<AxiosResponse> =>
  instance.client.delete(
    createPath(apiOrganizationMemberPath, { organizationId, memberId })
  );

export const getOrganizationInvitationStatus = (
  token: string
): Promise<AxiosResponse> =>
  instance.client.get(createPath(apiOrganizationsInviteAcceptPath, { token }));

export const acceptOrganizationInvite = async (token: string) =>
  instance.client.post<{
    organization: {
      id: number;
      logoUrl: string;
      name: string;
      slug: string;
    };
  }>(createPath(apiOrganizationsInviteAcceptPath, { token }));

export const getPeopleGroupsOfOrganization = async (
  organizationId: string,
  params?: IPaginationParams & { ids?: string }
) =>
  instance.client.get<IPaginationResponse<IGroup>>(
    createPath(apiOrganizationPeopleGroupsPath, { organizationId }),
    convertToSnakeCase({ params })
  );

export const createPeopleGroupInOrganization = (
  organizationId: string,
  group: IGroup
): Promise<AxiosResponse> =>
  instance.client.post(
    createPath(apiOrganizationPeopleGroupsPath, { organizationId }),
    convertToSnakeCase(group)
  );

export const getOrganizationPrinters = async (
  organizationId: string,
  params?: IPaginationParams
) =>
  await instance.client.get<IPaginationResponse<IPrinter>>(
    createPath(apiOrganizationPrintersPath, { organizationId }),
    convertToSnakeCase({ params })
  );

export const createOrganizationPrinter = (
  organizationId: string,
  data: object
): Promise<AxiosResponse> =>
  instance.client.post(
    createPath(apiOrganizationPrintersPath, { organizationId }),
    convertToSnakeCase(data)
  );

/*---------------- screening settings ------------ */
export const getOrganizationScreeningSettings = (
  organizationId: string
): Promise<IOrganizationScreeningConfig> =>
  instance.client.get(
    createPath(apiOrganizationScreeningPath, { organizationId })
  );

export const updateOrganizationScreeningSettings = (
  organizationId: string,
  data: Partial<IOrganizationScreeningConfig>
): Promise<IOrganizationScreeningConfig> =>
  instance.client.put(
    createPath(apiOrganizationScreeningPath, { organizationId }),
    convertToSnakeCase(data)
  );

export const getOrganizationScreeningTest = async (
  organizationId: number,
  params?: IPaginationParams & {
    sortField: string;
    sortOrder: string;
    status: string;
  }
): Promise<{ items: IScreening; count: number }> =>
  await instance.client.get<{ items: IScreening; count: number }>(
    createPath(apiOrganizationScreeningTestPath, { organizationId }),
    {
      params: params && {
        search: params.search,
        page: params.page,
        page_size: params.pageSize,
        ordering: params.sortField
          ? `${params.sortOrder === 'descend' ? '-' : ''}${snakeCase(
              params.sortField
            )}`
          : undefined,
        status: params.status,
      },
    }
  );

export const getScreeningDetail = async (screeingTestId: number) => {
  const res = await instance.client.get<{ item: IScreening }>(
    createPath(apiScreeningDetailPath, { screeingTestId })
  );
  return res.item;
};

/**----------Temperature device ---------------- */
export const getOrganizationTemperatureDevices = async (
  organizationId: string
) =>
  await instance.client.get<IPaginationResponse<ITemperatureDevice>>(
    createPath(apiOrganizationTemperatureDevicesPath, { organizationId })
  );

export const createOrganizationTemperatureDevices = (
  organizationId: string,
  data: Pick<ITemperatureDevice, 'name' | 'macAddress'>
) =>
  instance.client.post<ITemperatureDevice>(
    createPath(apiOrganizationTemperatureDevicesPath, { organizationId }),
    convertToSnakeCase(data)
  );

export const deactivateBulkOrganizationMembers = async (
  organizationId: string,
  items: number[]
) => {
  await instance.client.post(
    createPath(apiOrganizationBulkDeactivateMembersPath, { organizationId }),
    { items: items }
  );
  return true;
};

export const activateBulkOrganizationMembers = async (
  organizationId: string,
  items: number[]
) => {
  await instance.client.post(
    createPath(apiOrganizationBulkActivateMembersPath, { organizationId }),
    { items: items }
  );
  return true;
};
