/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import {
  IInvitationVerified,
  IVisit,
  IVisitVerify,
  REF_VMS_MODES,
  convertToCamelCase,
} from '@datapeace/1up-frontend-shared-api';
import {
  IBlobWithDataURL,
  useIsMounted,
} from '@datapeace/1up-frontend-web-utils';
import { IErrorResponse, toast } from '@datapeace/1up-frontend-web-ui';
import {
  ProcessType,
  useProcessDataContext,
  useProfileContext,
  useRouter,
} from '@datapeace/vms-web-models';
import {
  getSettingsFromLocalStorage,
  ROUTES,
  INVITATION_CODE_PREFIX,
  VISIT_QR_CODE_PREFIX,
  Api,
  ICustomErrorScreen,
} from '@datapeace/vms-web-utils';
import { useConfig } from '@datapeace/vms-web-hooks';

export function useCaptureContainer() {
  const { setCurrentRoute } = useRouter();
  const { email } = useProfileContext();
  const {
    currentSpace,
    isMobilePlan,
    visitorAppEnabled,
    checkinFormAutofillEnabled,
    checkoutFormAutofillEnabled,
  } = useConfig();
  const {
    processType,
    isUsingQr,
    invitationId,
    setProcessData,
    personData,
    visitRequestId,
  } = useProcessDataContext();
  const [captureMode, setCaptureMode] = useState(
    isUsingQr && !invitationId && !visitRequestId ? 'qr' : 'face'
  );
  const [customErrorScreen, setCustomErrorScreen] =
    useState<ICustomErrorScreen>(null);

  const {
    vmsMode = REF_VMS_MODES.NORMAL_MODE,
    detectionDistance,
    detectionScoreThreshold,
    compressImage,
    cameraFacing,
  } = getSettingsFromLocalStorage(email);

  const isFirstScreen = (
    [
      REF_VMS_MODES.QUICK_MODE_FACE,
      REF_VMS_MODES.QUICK_MODE_MOBILE,
      REF_VMS_MODES.AUTO_CAPTURE_MODE_FACE,
      REF_VMS_MODES.INVITED_QR_ONLY,
    ] as string[]
  ).includes(vmsMode);
  const isAutoCaptureEnabled = (
    [
      REF_VMS_MODES.AUTO_CAPTURE_MODE_FACE,
      REF_VMS_MODES.INVITED_QR_ONLY,
      REF_VMS_MODES.CONTACT_LESS_MODE,
    ] as string[]
  ).includes(vmsMode);

  const [capturedImage, setCapturedImage] = useState<IBlobWithDataURL | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  const isMountedRef = useIsMounted();

  // useEffect(() => handleEnteredCameraScreen(), [handleEnteredCameraScreen]);

  async function handleCapture(capturedImageData: IBlobWithDataURL | null) {
    if (!currentSpace?.id) return;

    try {
      setIsLoading(true);

      if (!capturedImageData || !capturedImageData.size) {
        throw new Error('Failed to capture! Please try again');
      }
      setCapturedImage(capturedImageData);
      const { fileUrl, people, activeVisit, lastVisit } =
        await Api.searchByFace(currentSpace?.id, {
          faceImage: capturedImageData,
          type: 'web',
        });
      if (!isMountedRef.current) return;

      if (isMobilePlan) {
        // In mobile plan, search by face is only used for uploading face data
        setProcessData({
          faceData: {
            imageUrl: fileUrl,
            dataUrl: capturedImageData.dataURL,
          },
          isFaceRequired: false,
        });
        setIsLoading(false);
        vmsMode === REF_VMS_MODES.INVITED_QR_ONLY
          ? setCurrentRoute(ROUTES.REGISTER)
          : setCurrentRoute(ROUTES.MOBILE_VERIFY);
        return;
      }

      if (processType === ProcessType.Checkout && !people) {
        throw new Error('You are not checked in!');
      }

      if (personData?.id && personData?.hasIndexedFaces) {
        if (personData?.id !== people?.id) {
          throw new Error('Face did not match to invited person!');
        }
      }

      setProcessData({
        faceData: {
          imageUrl: fileUrl,
          dataUrl: capturedImageData.dataURL,
        },
      });

      if (visitorAppEnabled && vmsMode === 'contactless') {
        setCurrentRoute(ROUTES.TEMPERATURE);
        return;
      }

      if (isUsingQr) {
        setCurrentRoute(ROUTES.REGISTER);
        return;
      }

      let currentProcessType = processType;
      if (isFirstScreen) {
        currentProcessType = activeVisit
          ? ProcessType.Checkout
          : ProcessType.Checkin;
        setProcessData({ processType: currentProcessType });
      }

      setProcessData({
        activeVisit,
        lastVisit,
        personData: people && { ...people },
        isFormAutofillEnabled:
          currentProcessType === ProcessType.Checkin
            ? checkinFormAutofillEnabled
            : checkoutFormAutofillEnabled,
      });

      if (activeVisit && activeVisit.visitInvitation) {
        const { id, checkoutFormDataRequired } = activeVisit.visitInvitation;
        setProcessData({
          invitationId: id,
          visitInvitation: activeVisit.visitInvitation,
        });

        if (
          currentProcessType === ProcessType.Checkout &&
          !checkoutFormDataRequired
        ) {
          setProcessData({ shouldFillForm: false });
        }
      }

      setCurrentRoute(ROUTES.REGISTER);
    } catch (err) {
      if (!isMountedRef.current) return;
      setIsLoading(false);
      setCapturedImage(null);
      const displayText = {
        ...convertToCamelCase((err as IErrorResponse)?.fields),
      }?.displayText as string | undefined;
      const title = (err as any)?.fields?.title;
      if ((err as any)?.response?.status === 400 && displayText && title) {
        setCustomErrorScreen({ title, displayText });
      } else toast.error(err as IErrorResponse);
    }
  }

  const handleVisitQrFound = async (
    res: IInvitationVerified | IVisitVerify
  ) => {
    try {
      const { visit, visitStateFlow, people, lastVisit, visitRequiredFields } =
        res;

      const { nextAllowedState } = visitStateFlow;
      const { isCheckinFaceRequired, isCheckoutFaceRequired } =
        visitRequiredFields;

      if (
        !(nextAllowedState === 'checkin' || nextAllowedState === 'checkout')
      ) {
        throw new Error('Visit QR is invalid or has expired!');
      }
      const isCheckinProcess = nextAllowedState === 'checkin';
      const isFaceRequired = isCheckinProcess
        ? isCheckinFaceRequired
        : nextAllowedState === 'checkout'
        ? isCheckoutFaceRequired
        : false;

      if (
        isCheckinProcess &&
        visit.visitInvitation &&
        !visit.visitInvitation.checkinAllowedNow
      ) {
        throw new Error(
          'You are not allowed to visit now. Please visit at your scheduled invited time'
        );
      }

      const { visitInvitationId } = visit as IVisit & {
        visitInvitationId: number;
      };

      setProcessData({
        processType: isCheckinProcess
          ? ProcessType.Checkin
          : ProcessType.Checkout,
        isUsingQr: true,
        personData: people,
        activeVisit: visit,
        isFormAutofillEnabled: isCheckinProcess
          ? checkinFormAutofillEnabled
          : checkoutFormAutofillEnabled,
        invitationId: visitInvitationId,
        visitRequestId: visit.visitRequestId,
        lastVisit,
      });
      if (isFaceRequired) {
        setCaptureMode('face');
      } else {
        setCurrentRoute(ROUTES.REGISTER);
      }
    } catch (err) {
      if (!isMountedRef.current) return;
      console.error(err);
      toast.error(err as IErrorResponse);
    }
  };

  const handleQrFound = async (qrContent?: string) => {
    if (!currentSpace?.id || isLoading) return;
    try {
      setIsLoading(true);
      if (qrContent?.startsWith(INVITATION_CODE_PREFIX)) {
        const invitationCode = qrContent.substring(
          INVITATION_CODE_PREFIX.length
        );
        const res = await Api.verifyInvitation(currentSpace.id, {
          invitationCode,
        });
        if (!isMountedRef.current) return;
        handleVisitQrFound(res);
      }
      if (qrContent?.startsWith(VISIT_QR_CODE_PREFIX)) {
        const visitId = qrContent.substring(VISIT_QR_CODE_PREFIX.length);
        const res = await Api.verifyVisitById(visitId);
        if (res.space?.id !== currentSpace.id) {
          throw new Error('Visit QR is invalid or has expired!');
        }
        if (!isMountedRef.current) return;
        handleVisitQrFound(res);
      }
    } catch (err) {
      console.error(err);
      if (!isMountedRef.current) return;
      toast.error(err as IErrorResponse);
    }
    setIsLoading(false);
  };
  const retry = () => setCustomErrorScreen(null);

  return {
    capturedImage,
    isFirstScreen,
    isAutoCaptureEnabled,
    detectionDistance,
    detectionScoreThreshold,
    handleBackButtonClick: () => setCurrentRoute(ROUTES.HOME),
    onFaceCapture: handleCapture,
    onQrFound: handleQrFound,
    isLoading,
    quality: compressImage ? 0.7 : 0.92,
    cameraFacing,
    isFaceDetectionEnabled: captureMode === 'face',
    isQrReaderEnabled: captureMode === 'qr',
    customErrorScreen,
    retry,
  };
}
