import { useState, useRef, useEffect, useCallback } from 'react';
import { IErrorResponse, message, toast } from '@datapeace/1up-frontend-web-ui';
import {
  useRouter,
  useProcessDataContext,
  ProcessType,
  useProfileContext,
} from '@datapeace/vms-web-models';
import {
  Api,
  checkIfVerificationRequired,
  ROUTES,
  getSettingsFromLocalStorage,
} from '@datapeace/vms-web-utils';
import { useCountdown, useIsMounted } from '@datapeace/1up-frontend-web-utils';
import { useConfig } from '@datapeace/vms-web-hooks';
import { REF_VMS_MODES } from '@datapeace/1up-frontend-shared-api';

export function useMobileVerificationContainer() {
  const { setCurrentRoute } = useRouter();
  const {
    registerData,
    checkinByMobile,
    processType,
    setProcessData,
    isCheckinOtpRequired,
    isCheckoutOtpRequired,
    isFaceRequired: isFaceRequiredForMobilePlan,
    otpTicket,
    visitRequestId,
    checkinVerified,
    checkoutVerified,
    notYouRegisterData,
    isNotYouFlow,
  } = useProcessDataContext();
  const {
    currentSpace,
    otpLength,
    isMobilePlan,
    verificationOtpSkipLimit,
    verificationResendOtpLimit,
    verificationResendOtpTimeout,
    supportedCallingCodes,
  } = useConfig();

  const isMountedRef = useIsMounted();

  const [formData, setFormData] = useState({ otp: '' });
  const [isLoading, setIsLoading] = useState(false);

  const [resendDelay, resetResendDelay] = useCountdown(
    verificationResendOtpTimeout || 10
  );

  const resendOTPbuttonClickCount = useRef(0);
  const { email } = useProfileContext();
  const { vmsMode } = getSettingsFromLocalStorage(email);

  const mobileNumber =
    (registerData && registerData.mobileNumber) ||
    (notYouRegisterData && notYouRegisterData.mobileNumber) ||
    '';

  const handleSubmit = async (codeValue?: string | null, skipOtp = false) => {
    setProcessData({ hasSkippedMobileVerification: skipOtp });
    try {
      if (!currentSpace) {
        throw new Error('Space is not selected!');
      }

      const otp = codeValue || formData.otp;

      if (!skipOtp) {
        if (!otp.trim() || otp.length !== otpLength) {
          throw new Error(`Please enter the OTP sent to ${mobileNumber}!`);
        }
      }

      setIsLoading(true);

      if (!checkinByMobile) {
        if (!skipOtp) {
          if (!visitRequestId) throw new Error('Visit Request Id not found!');
          await Api.verifyVisitRequestOTP(
            visitRequestId,
            mobileNumber,
            otp,
            otpTicket,
            processType === ProcessType.Checkin ? 'checkin' : 'checkout'
          );
          if (!isMountedRef.current) return;
        }
        setCurrentRoute(ROUTES.SCREENING_FORM);
        return;
      }

      if (isMobilePlan) {
        if (!skipOtp) {
          // Verify OTP of the space visitor
          await Api.verifySpaceVisitorOTP(
            currentSpace.id,
            mobileNumber,
            otp,
            otpTicket
          );
          if (!isMountedRef.current) return;
        }

        // Get list of visitors associated with the mobile number
        const res = await Api.getSpaceVisitorsByVerifiedMobileNumber({
          spaceId: currentSpace.id,
          mobile: mobileNumber,
          ticketId: otpTicket,
        });

        // redirect if visitors length is 0 and mobile plan
        if (res.visitors.length === 0 && !isNotYouFlow) {
          //if mode is quick and visitors are zero then assume it's checkin - so this will allow the registering of users.
          if (
            vmsMode === 'quick_mode_face' ||
            vmsMode === 'quick_mode_mobile'
          ) {
            setProcessData({
              processType: ProcessType.Checkin,
            });
            setCurrentRoute(ROUTES.REGISTER);
            return;
          }
          if (processType === ProcessType.Checkout) {
            message.error('You are not checked in!');
            setCurrentRoute(ROUTES.CHECKIN_BY_MOBILE);
            return;
          }
          setCurrentRoute(ROUTES.REGISTER);
          return;
        }
        // Select the visitor if only visitor option available
        if (res.visitors.length === 1) {
          let currentProcessType = processType;
          if (isMobilePlan) {
            const {
              item: { activeVisit, lastVisit },
            } = await Api.getSpaceVisitor({
              spaceId: currentSpace.id,
              visitorId: res.visitors[0]?.id,
            });
            if (
              vmsMode === REF_VMS_MODES.QUICK_MODE_FACE ||
              vmsMode === REF_VMS_MODES.QUICK_MODE_MOBILE
            ) {
              currentProcessType = activeVisit
                ? ProcessType.Checkout
                : ProcessType.Checkin;
            }
            setProcessData({
              processType: currentProcessType,
              activeVisit,
              lastVisit,
            });
          }
          // reset previous values and add notYouRegisterData
          setProcessData({
            personData: res.visitors[0],
            registerData: null,
            customFormData: null,
            notYouRegisterData: { ...res.visitors[0] },
          });
          setCurrentRoute(ROUTES.REGISTER);
          return;
        }

        // Take user to visitor selection
        setProcessData({
          availableVisitorListByMobileNumber: {
            mobile: mobileNumber,
            visitors: res.visitors,
          },
        });

        setCurrentRoute(ROUTES.VISITOR_SELECTION);

        return;
      }

      // If none of the above conditions are met, we move to NOT YOU flow from the code below

      // Verify OTP of the space visitor
      await Api.verifySpaceVisitorOTP(
        currentSpace.id,
        mobileNumber,
        otp,
        otpTicket
      );

      // Get list of visitors associated with the mobile number
      const res = await Api.getSpaceVisitorsByVerifiedMobileNumber({
        spaceId: currentSpace.id,
        mobile: mobileNumber,
        ticketId: otpTicket,
      });

      // Select the visitor if only visitor option available
      if (res.visitors.length === 1) {
        // reset previous values and add notYouRegisterData
        setProcessData({
          personData: res.visitors[0],
          registerData: null,
          customFormData: null,
          notYouRegisterData: { ...res.visitors[0] },
        });
        setCurrentRoute(ROUTES.REGISTER);
        return;
      }

      // Take user to visitor selection
      setProcessData({
        availableVisitorListByMobileNumber: {
          mobile: mobileNumber,
          visitors: res.visitors,
        },
      });
      setCurrentRoute(ROUTES.VISITOR_SELECTION);
    } catch (err) {
      if (!isMountedRef.current) return;
      setFormData({ otp: '' });
      setIsLoading(false);
      toast.error(err as IErrorResponse);
    }
  };

  const handleResendOtp = async () => {
    try {
      if (!mobileNumber) {
        throw new Error('Mobile is required to continue.');
      }
      resetResendDelay();
      // Mobile Plan
      if (isMobilePlan) {
        if (!currentSpace) {
          throw new Error('Space not selected!');
        }
        await Api.resendVisitorOTP(
          isMobilePlan,
          currentSpace?.id,
          mobileNumber,
          undefined,
          processType === 'Checkin' ? 'checkin' : 'checkout'
        );
      } else if (checkinByMobile) {
        // Not You Flow
        if (!currentSpace) {
          throw new Error('Space not selected!');
        }
        const res = await Api.resendSpaceVisitorOTP(
          isMobilePlan,
          currentSpace.id,
          mobileNumber,
          undefined,
          otpTicket
        );
        setProcessData({ otpTicket: res.verificationTicket.id });
      } else {
        // Mobile Verification for a known visitor
        if (!visitRequestId) {
          throw new Error('Visit request Id not found!');
        }

        const res = await Api.resendVisitRequestOTP(
          isMobilePlan,
          visitRequestId,
          mobileNumber,
          undefined,
          otpTicket,
          processType === 'Checkin' ? 'checkin' : 'checkout'
        );
        setProcessData({ otpTicket: res.verificationTicket.id });
      }
      // Skip and reset limit is not checked when check in is done by mobile
      if (verificationResendOtpLimit && !checkinByMobile) {
        resendOTPbuttonClickCount.current += 1;
        setProcessData({
          verifyOtpAttempt: resendOTPbuttonClickCount.current,
        });
      }
      if (!isMountedRef.current) return;
      toast.success({ message: 'OTP sent' });
    } catch (err) {
      if (!isMountedRef.current) return;
      toast.error(err as IErrorResponse);
    }
  };

  const handleBackNavigation = useCallback(() => {
    setProcessData({ notYouRegisterData: null });
    setCurrentRoute(ROUTES.CHECKIN_BY_MOBILE);
  }, [setProcessData, setCurrentRoute]);

  const isVerificationRequired = checkIfVerificationRequired(
    isMobilePlan,
    checkinVerified,
    checkoutVerified,
    processType,
    {
      isCheckinOtpRequired,
      isCheckoutOtpRequired,
    },
    (vmsMode === REF_VMS_MODES.QUICK_MODE_FACE ||
      vmsMode === REF_VMS_MODES.QUICK_MODE_MOBILE) &&
      isMobilePlan,
    mobileNumber,
    supportedCallingCodes
  );

  useEffect(() => {
    if (
      checkinByMobile &&
      ((processType === ProcessType.Checkin &&
        isCheckinOtpRequired &&
        !checkinVerified) ||
        (processType === ProcessType.Checkout &&
          isCheckoutOtpRequired &&
          !checkoutVerified))
    ) {
      return;
    }

    if (isFaceRequiredForMobilePlan && isMobilePlan) {
      setCurrentRoute(ROUTES.CAPTURE);
      return;
    }
    if (!isVerificationRequired) {
      setCurrentRoute(ROUTES.SCREENING_FORM);
    }
  });

  const fields = [
    {
      type: 'verificationCode',
      name: 'otp',
      label: `Please enter the OTP sent to ${mobileNumber}`,
      codeLength: otpLength,
      onSubmit: handleSubmit,
      disabled: isLoading,
    },
  ];

  // Skip and reset limit is not checked when check in is done by mobile
  return {
    fields,
    formData,
    setFormData,
    onSubmit: handleSubmit,
    resendDelay,
    handleResendOtp,
    isLoading,
    isResendLimitExceeded:
      !checkinByMobile &&
      !!verificationResendOtpLimit &&
      resendOTPbuttonClickCount.current >= verificationResendOtpLimit,
    isSkipOtpButtonVisible:
      !checkinByMobile &&
      !!verificationOtpSkipLimit &&
      resendOTPbuttonClickCount.current >= verificationOtpSkipLimit,
    handleBackNavigation,
  };
}
