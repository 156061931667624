import { FC, useState, useEffect } from 'react';
import {
  getMaskedMobileNumber,
  getPeopleForFormSearch,
  getPerson,
} from '@datapeace/1up-frontend-shared-api';
import { Spin, Typography } from 'antd';
import {
  IErrorResponse,
  MailOutlined,
  MobileOutlined,
  toast,
} from '@datapeace/1up-frontend-web-ui';
import { useConfig } from '@datapeace/vms-web-hooks';
import { Dropdown, DropdownValue } from './drop-down-dynamic-field';
import { debounce, useRefValue } from '@datapeace/1up-frontend-web-utils';
import styles from './people-dynamic-field.module.scss';
import { getParsedValueFromFormFieldData } from '../../utils/form';

export interface IPeopleProps {
  name: string;
  value?: DropdownValue;
  onChange: (name: string, value: IPeopleProps['value']) => void;
  placeholder?: string;
  data?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    peopleGroups?: any;
  };
  disabled?: boolean;
  settings: {
    personSelectionFields?: string[];
  };
}

const pageSize = 10;
const searchDebounceDelay = 400;

interface Option {
  key: string;
  label: string;
  iconSrc?: string;
  isSetManually?: boolean;
  content?: JSX.Element | null;
}

export const People: FC<IPeopleProps> = ({
  onChange,
  name,
  value,
  placeholder,
  disabled,
  data,
  settings,
}) => {
  const [options, setOptions] = useState<Option[]>(
    value && (value as { key: string; label: string; value: string })?.key
      ? [
          {
            ...(value as { key: string; label: string; value: string }),
            isSetManually: true,
          },
        ]
      : []
  );
  const [isFetching, setIsFetching] = useState(false);

  const config = useConfig();

  const orgId =
    config && config.currentOrganization && config.currentOrganization.id;

  const personId =
    (value && (value as { key: string; label: string; value: string })?.key) ||
    null;
  const shouldCheckIfPersonExists =
    !!personId && options.length && options[0].isSetManually === true;

  const onChangeRef = useRefValue(onChange);
  useEffect(() => {
    // check if person exists
    if (!personId || !shouldCheckIfPersonExists) return;

    const fetchPersonData = async () => {
      setIsFetching(true);

      try {
        // if person by this id not found assume it's DELETED, so clear the value
        const person = await getPerson(+personId);
        if (!person) {
          onChangeRef.current(name, '');
          setIsFetching(false);
          return;
        }

        const {
          id,
          firstName,
          lastName,
          pictureUrl,
          personCustomData,
          mobileNumber,
          email,
        } = person;
        setOptions([
          {
            key: String(id),
            label: `${firstName} ${lastName}`,
            iconSrc: pictureUrl,
            content:
              settings?.personSelectionFields &&
              settings?.personSelectionFields.length > 0 ? (
                <div>
                  {settings?.personSelectionFields.map((item) => {
                    const customDataItem = personCustomData?.data?.find(
                      (data) => data?.label === item
                    );
                    if (item === 'MOBILE_NUMBER') {
                      return mobileNumber ? (
                        <div key={item} className={styles.Content_Container}>
                          <MobileOutlined style={{ fontSize: '12px' }} />
                          &nbsp;
                          <Typography.Text
                            style={{ fontSize: '12px' }}
                            ellipsis={{
                              tooltip: getMaskedMobileNumber(mobileNumber),
                            }}
                            className={styles.Content_Ellipsis}
                          >
                            {getMaskedMobileNumber(mobileNumber)}
                          </Typography.Text>
                        </div>
                      ) : null;
                    }
                    if (item === 'EMAIL') {
                      return email ? (
                        <div key={item} className={styles.Content_Container}>
                          <MailOutlined style={{ fontSize: '12px' }} />
                          &nbsp;
                          <Typography.Text
                            style={{ fontSize: '12px' }}
                            ellipsis={{
                              tooltip: email,
                            }}
                            className={styles.Content_Ellipsis}
                          >
                            {email}
                          </Typography.Text>
                        </div>
                      ) : null;
                    }
                    return customDataItem?.label ? (
                      <div key={item} className={styles.Content_Container}>
                        <Typography.Text
                          style={{
                            fontWeight: 'bolder',
                            fontSize: '12px',
                          }}
                          ellipsis={{
                            tooltip: customDataItem?.label,
                          }}
                          className={styles.Content_Ellipsis}
                        >
                          {customDataItem?.label}
                          &nbsp;:&nbsp;
                        </Typography.Text>
                        <Typography.Text
                          style={{ fontSize: '12px' }}
                          ellipsis={{
                            tooltip: customDataItem?.value[0]?.label || '',
                          }}
                          className={styles.Content_Ellipsis}
                        >
                          {getParsedValueFromFormFieldData(customDataItem) ||
                            '--'}
                        </Typography.Text>
                      </div>
                    ) : null;
                  })}
                </div>
              ) : null,
          },
        ]);
      } catch {
        onChangeRef.current(name, '');
      }

      setIsFetching(false);
    };

    fetchPersonData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId, shouldCheckIfPersonExists, onChangeRef, name]);

  const fetchPeopleFirstPage = debounce(async (searchText?: string) => {
    if (!orgId || !data || !data.peopleGroups || !data.peopleGroups.length) {
      setOptions([]);
      return;
    }

    setIsFetching(true);

    try {
      const { items } = await getPeopleForFormSearch(orgId, {
        page: 1,
        pageSize,
        search: searchText,
        ...(data.peopleGroups === 'all'
          ? { personGroupNone: false }
          : { personGroupIsOr: data.peopleGroups }),
      });
      setOptions(
        items.map(
          ({
            id,
            firstName,
            lastName,
            pictureUrl,
            personCustomData,
            mobileNumber,
            email,
          }) => ({
            key: String(id),
            label: `${firstName} ${lastName}`,
            iconSrc: pictureUrl,
            content:
              settings?.personSelectionFields &&
              settings?.personSelectionFields.length > 0 ? (
                <div>
                  {settings?.personSelectionFields.map((item) => {
                    const customDataItem = personCustomData?.data?.find(
                      (data) => data?.label === item
                    );
                    if (item === 'MOBILE_NUMBER') {
                      return mobileNumber ? (
                        <div key={item} className={styles.Content_Container}>
                          <MobileOutlined style={{ fontSize: '12px' }} />
                          &nbsp;
                          <Typography.Text
                            style={{ fontSize: '12px' }}
                            ellipsis={{
                              tooltip: getMaskedMobileNumber(mobileNumber),
                            }}
                            className={styles.Content_Ellipsis}
                          >
                            {getMaskedMobileNumber(mobileNumber)}
                          </Typography.Text>
                        </div>
                      ) : null;
                    }
                    if (item === 'EMAIL') {
                      return email ? (
                        <div key={item} className={styles.Content_Container}>
                          <MailOutlined style={{ fontSize: '12px' }} />
                          &nbsp;
                          <Typography.Text
                            style={{ fontSize: '12px' }}
                            ellipsis={{
                              tooltip: email,
                            }}
                            className={styles.Content_Ellipsis}
                          >
                            {email}
                          </Typography.Text>
                        </div>
                      ) : null;
                    }
                    return (
                      <div key={item} className={styles.Content_Container}>
                        <Typography.Text
                          style={{ fontSize: '12px' }}
                          ellipsis={{
                            tooltip: item,
                          }}
                          className={styles.Content_Ellipsis}
                        >
                          {item}
                          &nbsp;:&nbsp;
                        </Typography.Text>
                        <Typography.Text
                          style={{ fontSize: '12px' }}
                          ellipsis={{
                            tooltip: customDataItem?.value[0]?.label || '',
                          }}
                          className={styles.Content_Ellipsis}
                        >
                          {getParsedValueFromFormFieldData(customDataItem) ||
                            '--'}
                        </Typography.Text>
                      </div>
                    );
                  })}
                </div>
              ) : null,
          })
        )
      );
    } catch (err) {
      toast.error(err as IErrorResponse);
    }

    setIsFetching(false);
  }, searchDebounceDelay);

  return (
    <Dropdown
      loading={isFetching}
      name={name}
      value={value}
      options={options}
      placeholder={placeholder || 'Search for person'}
      disabled={disabled}
      notFoundContent={isFetching ? <Spin size="small" /> : 'No people found!'}
      filterOption={false}
      onSearch={fetchPeopleFirstPage}
      onFocus={() => fetchPeopleFirstPage()}
      showSearch
      labelInValue
      onChange={onChange}
    />
  );
};
