export const API_BASE_URL = import.meta.env.VITE_APP_API_BASE_URL;
export const ADMIN_APP_PATH = import.meta.env.VITE_APP_ADMIN_APP_PATH;
export const SENTRY_DSN = import.meta.env.VITE_APP_SENTRY_DSN;
export const ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT;
export const VISITOR_APP_URL = import.meta.env.VITE_APP_VISITOR_BASE_URL;
export const VISITOR_APP_DOMAIN_TEXT =
  VISITOR_APP_URL && new URL(VISITOR_APP_URL).host;
export const BRAND_NAME = import.meta.env.VITE_APP_BRAND_NAME;
export const ASSET_BASE_PATH = import.meta.env.VITE_APP_ASSET_BASE_PATH;
export const DISABLED_TNC_EMAIL: string | boolean =
  import.meta.env.VITE_APP_DISABLE_TNC_EMAIL_ME_COPY || false;

if (API_BASE_URL === undefined) {
  throw Error(
    'Please set environment variable `VITE_APP_API_BASE_URL` correctly.'
  );
}

if (ADMIN_APP_PATH === undefined) {
  throw Error(
    'Please set environment variable `VITE_APP_ADMIN_APP_PATH` correctly.'
  );
}

if (ASSET_BASE_PATH === undefined || ASSET_BASE_PATH === null) {
  throw Error(
    'Please set ASSET_BASE_PATH environment variables correctly! Empty string can also be set.'
  );
}

if (!BRAND_NAME) {
  throw Error('Please set BRAND_NAME environment variables correctly!');
}

export const ROUTES = {
  HOME: '/',
  CONTACT_LESS_WELCOME: '/contact-less-welcome',
  CAPTURE: '/capture',
  REGISTER: '/register',
  CHECKIN_BY_MOBILE: '/not-you',
  VISITOR_SELECTION: '/visitor-selection',
  CHECK_IN_FORM: '/check-in-form',
  SCREENING_FORM: '/screening-form',
  VACCINATION_FORM: '/vaccination-form',
  TERMS: '/terms',
  TEMPERATURE: '/temperature',
  MOBILE_VERIFY: '/mobile-verify',
  CHECK_IN_SUCCESS: '/check-in-success',
  SETTINGS: '/settings',
  VISIT_ERROR_SCREEN: '/visit-error',
} as const;

export const RESTRICTED_MODE_VMS_MODE_KEY = () => `vms_mode`;
export const LOGOUT_REDIRECT_KEY = () => `logout_redirect`;
export const LOGIN_PATH = `${ADMIN_APP_PATH}/signin`;
export const LOGOUT_PATH = `${ADMIN_APP_PATH}/signout`;

export const UPGRADE_MESSAGE =
  'Please upgrade your plan to access this feature!';

export const VMS_LOGGED_OUT_EVENT = '1upvms-logged-out';
export const COMPARISON_OPERATORS = {
  EQUAL: '==',
  NOT_EQUAL: '!=',
};

export const TEMPERATURE_FETCH_DEFAULT_TIME = '15'; // 15 seconds

export const MAX_SCREEN_IDLE_TIME = 1 * 60 * 1000; // 1 Mins

export const DETECTION_DOWNSCALING = 2;
export const BUTTON_TEXT_MAX_LENGTH = 50;

export const INVITATION_CODE_PREFIX = 'ONEUPVISION:VISIT_INVITATION:';
export const VISIT_QR_CODE_PREFIX = 'ONEUPVISION:VISIT:';
