import {
  REFRESH_TOKEN_STORAGE_KEY,
  TOKEN_STORAGE_KEY,
  startAccessTokenRefreshProcess,
  storage,
  REF_VMS_MODES,
} from '@datapeace/1up-frontend-shared-api';
import {
  LOGOUT_REDIRECT_KEY,
  RESTRICTED_MODE_VMS_MODE_KEY,
} from '@datapeace/vms-web-utils';
import { captureException } from '@sentry/react';

export interface ISettings {
  cameraFacing: 'user' | 'environment';
  compressImage: boolean;
  vmsMode: ValueOf<typeof REF_VMS_MODES>;
  detectionScoreThreshold: number;
  detectionDistance: number;
  pmaCode: string;
  temperatureFetchMaxTime: number;
  temperatureDeviceId: number | undefined;
  successScreenAutoClose: boolean;
  successScreenAutoCloseTimeout: number;
  displaySpaceSelectionOnHomeScreen: boolean;
}

export function getSettingsFromLocalStorage(email: string): Partial<ISettings> {
  try {
    const settingsFromLS = storage.getItem(`${email}-settings`);
    const settings = JSON.parse(
      settingsFromLS || '{}'
    ) as Partial<ISettings> | null;

    if (!settings) return defaultSettings;

    // validations
    if (settings.detectionScoreThreshold) {
      if (
        settings.detectionScoreThreshold < detectionScoreThresholdRange.min ||
        settings.detectionScoreThreshold > detectionScoreThresholdRange.max
      ) {
        settings.detectionScoreThreshold =
          defaultSettings.detectionScoreThreshold;
      }
    }

    return { ...defaultSettings, ...settings };
  } catch (e) {
    return { ...defaultSettings };
  }
}

export function saveSettingsIntoLocalStorage(
  email: string,
  settings: ISettings
) {
  return storage.setItem(`${email}-settings`, JSON.stringify(settings));
}

export function setRestrictedVMSModeInStorage(
  value: ValueOf<typeof REF_VMS_MODES>
) {
  return storage.setItem(RESTRICTED_MODE_VMS_MODE_KEY(), String(value));
}

export function removeRestrictedVMSModeFromStorage() {
  return storage.removeItem(RESTRICTED_MODE_VMS_MODE_KEY());
}

export function getRestrictedVMSModeFromStorage() {
  return storage.getItem(RESTRICTED_MODE_VMS_MODE_KEY()) as ValueOf<
    typeof REF_VMS_MODES
  >;
}

export function setLogoutRedirectUrlInStorage(value: string) {
  return storage.setItem(LOGOUT_REDIRECT_KEY(), String(value));
}

export function removeLogoutRedirectUrlFromStorage() {
  return storage.removeItem(LOGOUT_REDIRECT_KEY());
}

export function getLogoutRedirectUrlFromStorage() {
  return storage.getItem(LOGOUT_REDIRECT_KEY());
}

export const detectionScoreThresholdRange = { min: 10, max: 400 } as const;
export const defaultSettings: Pick<
  ISettings,
  | 'detectionDistance'
  | 'detectionScoreThreshold'
  | 'cameraFacing'
  | 'vmsMode'
  | 'temperatureFetchMaxTime'
  | 'successScreenAutoCloseTimeout'
  | 'displaySpaceSelectionOnHomeScreen'
  | 'successScreenAutoClose'
  | 'compressImage'
> = {
  detectionDistance: 150,
  detectionScoreThreshold: 40,
  cameraFacing: 'user',
  vmsMode: REF_VMS_MODES.NORMAL_MODE,
  temperatureFetchMaxTime: 15,
  successScreenAutoCloseTimeout: 3,
  displaySpaceSelectionOnHomeScreen: false,
  compressImage: true,
  successScreenAutoClose: true,
};

export function setTokensInStorage(accessToken: string, refreshToken?: string) {
  // trims the text 'bearer ' from the token string, if present
  storage.setItem(
    TOKEN_STORAGE_KEY,
    accessToken && accessToken.split(' ')?.[0] === 'bearer'
      ? accessToken.substring(accessToken.indexOf(' ') + 1)
      : accessToken
  );

  if (refreshToken) {
    storage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
    startAccessTokenRefreshProcess().catch((err) => {
      console.error(err);
      captureException(err);
    });
  }
}

export function getAccessTokenFromStorage() {
  const token = storage.getItem(TOKEN_STORAGE_KEY);
  // trims the text 'bearer ' from the token string, if present
  if (token && token.split(' ')?.[0] === 'bearer') {
    return token.substr(token.indexOf(' ') + 1);
  }

  return token;
}
