import React from 'react';
import clsx from 'clsx';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from '../icons';
import styles from './status-card.module.scss';
import { Card } from '../card';

export interface StatusCardProps {
  // enter prop types here
  status?: 'idle' | 'loading' | 'error' | 'success';

  avatar?: React.ReactNode;
  title?: React.ReactNode;
  meta?: React.ReactNode;
  /** if true, background color will be changed for success and error status. otherwise text color will be changed */
  highlight?: boolean;

  actions?: React.ReactNode[];
  idleActions?: React.ReactNode[];
  loadingActions?: React.ReactNode[];
  errorActions?: React.ReactNode[];
  successActions?: React.ReactNode[];

  message?: React.ReactNode;
  idleMessage?: React.ReactNode;
  loadingMessage?: React.ReactNode;
  errorMessage?: React.ReactNode;
  successMessage?: React.ReactNode;

  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export const StatusCard: React.FC<StatusCardProps> = ({
  status = 'idle',
  avatar,
  title,
  meta,
  highlight = false,

  idleActions,
  loadingActions,
  errorActions,
  successActions,
  actions,

  idleMessage,
  loadingMessage,
  errorMessage,
  successMessage,
  message,

  className,
  style,
  children,
}) => {
  let options;
  switch (status) {
    case 'idle': {
      options = {
        cardType: 'default' as const,
        cardActions: idleActions || actions,
        cardMessage: idleMessage || message,
      };
      break;
    }
    case 'loading': {
      options = {
        cardType: 'default' as const,
        cardIcon: <LoadingOutlined />,
        cardActions: loadingActions || actions,
        cardMessage: loadingMessage || message || 'Loading...',
      };
      break;
    }
    case 'error': {
      options = {
        cardType: 'danger' as const,
        cardIcon: <CloseCircleOutlined />,
        cardActions: errorActions || actions,
        cardMessage: errorMessage || message || 'Failed!',
      };
      break;
    }
    default: {
      options = {
        cardType: 'success' as const,
        cardIcon: <CheckCircleOutlined />,
        cardActions: successActions || actions,
        cardMessage: successMessage || message || 'Success!',
      };
    }
  }

  return (
    <Card
      className={clsx(
        styles.StatusCard,
        styles[`Card_${status}`],
        highlight && styles.Card_Highlight,
        className
      )}
      style={style}
      avatar={avatar}
      title={
        (title || meta) && (
          <h2 className={styles.Title} style={{ whiteSpace: 'normal' }}>
            {title}
            {!!title && !!meta && <br />}
            <small className={styles.Meta}>{meta}</small>
          </h2>
        )
      }
      type={options.cardType}
      actions={options.cardActions}
      bodyStyle={{ padding: 0 }}
    >
      {(options.cardMessage || options.cardIcon) && (
        <h3 className={styles.Message} style={{ fontWeight: 'normal' }}>
          {options.cardIcon && (
            <>
              {options.cardIcon}
              &nbsp;
            </>
          )}
          {options.cardMessage}
        </h3>
      )}
      {!!children && (
        <div style={{ padding: '0.75rem', whiteSpace: 'pre-wrap' }}>
          {children}
        </div>
      )}
    </Card>
  );
};
